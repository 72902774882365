/* @import '../../../public/assets/searchicon.png' */
body {
  overflow-x: hidden;
}

.innerpage-desc {
  padding-left: 2rem;
  padding-right: 8rem !important;
}
.cart-icons {
  padding: 2px;
}
.product-rugs {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.875px;
}
/* algolia work css start */
.hitstyle {
  /* text-align: --web; */
  flex-wrap: wrap;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.hitstyle button {
  width: 100%;
  text-align: -webkit-center;
  border: none;
  margin: 5rem 0;

  background: transparent;
}
.hitstyle button:focus {
  outline: none;
}
.hitstyle button:hover {
  outline: none;
  background-color: transparent;
}
.hitstyle ul {
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.hitstyle ul li {
  /* flex: 0 0 50%;
  max-width: 50%; */
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  list-style: none;
  display: inline;
}
/* btn */
.pagination-btn ul li {
  list-style: none;
  display: inline;
}
.pagination-btn a {
  text-decoration: none;
}

.showmore-btn {
  background-color: black;
  height: 47px;
  width: 270px;
  border-radius: 0px;
  z-index: 2;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

/* btn */
/* algolia work css end */
/* .mainscreen {
  /* padding: 3rem; */

/* padding-left: 10rem; */
/* padding-right: 5rem; */
.mainscreen img {
  max-height: 520px;
  max-width: auto;
  border-radius: 0px;
}
.links a {
  text-decoration: none;
  text-align: center;
  font-family: "Roboto", normal;
  color: black;
  /* padding-right: 10px; */
  margin-bottom: 10px;
}
/* checkbox editable  start*/

/* The contain */
.checkbox-label {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
}
/* .text-white {
  color: white;
} */

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.product-title {
  margin: 3rem 0 0 3rem;
}
.no-image-bg{
  background-color: #ededed;
}
/* checkbox editable end */
/* .productcardimg {
  aspect-ratio: 270/270;
} */
.p16font {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25em;
  text-align: left;
  display: block;
}
.single-product-title {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25em;
  text-align: left;
  display: block;
}
.p14font {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}
.p18font {
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}

.sub-img {
  display: inline-flex;
}
.sub-imgpart {
  max-height: 89px;
  max-width: auto;
  left: 180.333251953125px;
  top: 757px;
  border-radius: 0px;
  border: 1px solid black;
}
.filter-text {
  font-size: 18px;
  color: #828282;
}
.filterbtn option {
  padding-right: 5rem;
  height: 48px;
}
.filterbtn {
  /* styling */
  background-color: white;
  border: 0.1 solid black;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 100%;
  background-color: transparent;
  height: 48px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAB7UlEQVRIie2QT0iTcRjHv7+971uH6lAwooIOttlqcxphFBEM3R/eFljBS4FFBBV0CYROnTrUPTxKXSI6GEFZMX21HKsRJrbed8NteuigRSGEDY3Z9nueTobUnKt53Of6fb6fLzxAgwb1opy9eCXb7Gudydqp/HqK2wPB4PbdTY8Vj9eXU1Tt4R5vy2w+baXXQ37wWDBKwAOSfEmZTFsfm1vbEqpw3Hf7/PP5tDVRj/xQZ/gkEfdBlrs+JEffKACQs1KzHv+BIQf4rsvrL05l7PH/kbcHwt1EfIdJ6qlkfAIAlOUwZ6e+un1tgw5Qn8uzX5uezCT/Sd6hX2XmW0KIyPvEy9+vVlYeTWWsuSbXvqdQ1V73Xu+W6WwmUYv8cOfxa2C6rkJ0vIubuZWZqFQ4YRi7iNWRclkOmk/6e6rL9RsMvkAKBcdNc+bPvOIAAEROn9vBKA1zmUaGBx71AOC/5KHobRCdEqyE3r56/qmSZ9UBAIgYxjb5E0OSyR4daLkM3KTl3pFQtJeJjmobEHkdi82t5qg6AAABw9js+CFfSNAXsTjf7XQ6+fP34j3ikpsKQh8bixWq9dccAIBw+PymJa3wjIm+MTNLyVsXRKnLNs3Ftbo1DQCAS9c37mStn5mgLC2cicfjxVq7DRrUxy9bgs+Fme8W0AAAAABJRU5ErkJggg==);
  background-position: calc(100% - 20px) calc(0.8em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 15px;
  background-repeat: no-repeat;
}
/* display none and visible */
.desktop-visible {
  display: content;
}
.mob-visible {
  display: none;
}
.mob-search {
  display: none;
}
@media screen and (min-width: 1200px) {

  .productcardimg {
    width: 270px;
    height: 270px;
    /* aspect-ratio: 270/270; */
  }

}
@media screen and (max-width: 1200px) {

  .productcardimg {
    /* max-width: 270px; */
    /* height: 270px; */
    aspect-ratio: 270/270;
  }

}

@media screen and (max-width: 992px) {
  .innerpage-desc {
    padding-left: 0;
    padding-right: 6rem !important;
  }
  /* .productcardimg {
    height: 240px;
    width: 240px;
  } */

  /* checkbox editable  start*/

  /* The contain */
  .checkbox-label {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
  }
  /* .text-white {
  color: white;
} */

  /* Hide the browser's default checkbox */
  .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid black;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .contain input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .contain .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* checkbox editable end */
}
.sub-imgpart {
  width: auto;
  height: 89px;
}
.mob-subimgmax {
  /* max-width: 79.017093658447266px; */
  max-width: auto;
  max-height: 79.76179885864258px;
  /* margin:1rem; */
}
.displayscreen {
  text-align-last: center;
}

@media screen and (max-width: 1200px) {
  .main-img {
    width: 300px;
    height: 300px;
  }
  /* .mainscreen {
    padding: 3rem;

    padding-left: 5rem;
  }
  .mainscreen img {
    height: 520px;
    width: 358px; 
  }*/
}
@media screen and (max-width: 992px) {
  .main-img {
    width: 300px;
    height: 300px;
  }
  /* .in {
    padding: 3rem;

    padding-left: 3rem;
    padding-right: 3rem !important;
  }
  .mainscreen img {
    height: 420px;
    width: 308px;
  } */
}

@media screen and (min-width: 575px) and (max-width: 768px) {
  .screen {
    padding: 5rem;
  }
  .innerpage-desc {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important ;
  }
  .hitstyle ul li {
    /* flex: 0 0 50%;
    max-width: 50%; */
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    list-style: none;
    display: inline;
  }
  /* .mainscreen {
    padding: 3rem;

  }
  .mainscreen img {
    height: 420px;
    width: 308px;
  } */
  .main-img {
    width: 300px;
    height: 300px;
  }
  /* .mainscreen {
    padding: 3rem;
    position: relative;
    padding-left: 2rem;
    padding-right: 3rem !important;
  }
  .mainscreen img {
    position: absolute;
    height: 380px;
    width: 288px;
  } */
  .productcardimg {
    max-height: 160px;
    max-width: 160px;
  }
  .p16font {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.25em;
    text-align: left;
  }
  .single-product-title {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.25em;
    text-align: left;
  }
  .p14font {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: contents;
  }

  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .search-padding {
    /* padding-top: 4.5rem; */
  }
  .innerpage-desc {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important ;
  }
  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: contents;
  }
  .displayscreen {
    text-align-last: center;
  }
  .product-rugs {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .mob-subimgmax {
    max-width: 40.017093658447266px;
    max-height: 39.76179885864258px;
  }
  .sub-imgpart {
    height: 55.017093658447266px;
    width: 55.76179885864258px;
    /* left: 89.213623046875px; */
    /* top: 405.15087890625px; */
    border-radius: 0px;
    border: 1px solid black;
  }
  .product-title {
    margin: 3rem 0 0 0;
  }
  .mob-search {
    display: contents;
  }
  .main-img-mob {
    height: 230px;
    width: 158px;
    left: 110px;
    top: 159px;
    border-radius: 0px;
  }
  button.ais-SearchBox-submit {
    background: transparent;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .searchbtn {
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 1px solid 5rem;

    /* background-image: url("../../component/assets/searchicon.png"); */
    /* background-image: url('../../../public/assets/searchicon.png'); */
    background-position: calc(100% - 20px) calc(0.5em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 25px;
    background-repeat: no-repeat;
  }
  .searchbtn input {
    border: none;
    /* border-radius:5rem; */
    background-color: #f5f5f5;
    padding: 16px;
    width: 85%;
  }
  .searchbtn button {
    background-color: #f5f5f5;
    background-color: transparent;
    border: transparent;
    /* background-image:url('../assets/searchicon.png'); */
    /* display: none; */
  }
  .searchbtn input :focus {
    border: none;
    text-decoration: none;
  }
  .mob-filterbtn ul {
    padding: 0;
    margin: 0;
  }
  .filterbtn option {
    padding-right: 5rem;
    height: 40px;
  }
  .filterbtn {
    /* styling */
    background-color: white;
    border: 0.1 solid black;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    width: 100%;
    background-color: transparent;
    height: 40px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAB7UlEQVRIie2QT0iTcRjHv7+971uH6lAwooIOttlqcxphFBEM3R/eFljBS4FFBBV0CYROnTrUPTxKXSI6GEFZMX21HKsRJrbed8NteuigRSGEDY3Z9nueTobUnKt53Of6fb6fLzxAgwb1opy9eCXb7Gudydqp/HqK2wPB4PbdTY8Vj9eXU1Tt4R5vy2w+baXXQ37wWDBKwAOSfEmZTFsfm1vbEqpw3Hf7/PP5tDVRj/xQZ/gkEfdBlrs+JEffKACQs1KzHv+BIQf4rsvrL05l7PH/kbcHwt1EfIdJ6qlkfAIAlOUwZ6e+un1tgw5Qn8uzX5uezCT/Sd6hX2XmW0KIyPvEy9+vVlYeTWWsuSbXvqdQ1V73Xu+W6WwmUYv8cOfxa2C6rkJ0vIubuZWZqFQ4YRi7iNWRclkOmk/6e6rL9RsMvkAKBcdNc+bPvOIAAEROn9vBKA1zmUaGBx71AOC/5KHobRCdEqyE3r56/qmSZ9UBAIgYxjb5E0OSyR4daLkM3KTl3pFQtJeJjmobEHkdi82t5qg6AAABw9js+CFfSNAXsTjf7XQ6+fP34j3ikpsKQh8bixWq9dccAIBw+PymJa3wjIm+MTNLyVsXRKnLNs3Ftbo1DQCAS9c37mStn5mgLC2cicfjxVq7DRrUxy9bgs+Fme8W0AAAAABJRU5ErkJggg==);
    background-position: calc(100% - 20px) calc(0.8em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  .hitstyle ul {
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    /* padding-right: 10%; */
    padding-left: 10%;
    margin-top: var(--bs-gutter-y);
  }

  .hitstyle ul li {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    list-style: none;
    display: inline;
  }
  .productcard {
    max-width: 152px;
    max-height: auto;
    border: 0;
    /* margin: 0 .5rem; */
  }
  .productcardimg {
    max-height: 146.368408203125px;
    max-width: 152px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
  .checkboxfilter {
    display: none;
  }
  .bestsell {
    display: none;
  }
  .mainscreen {
    /* padding: 3rem; */
    position: relative;
    /* padding-left:1rem;
    padding-right: 3rem !important; */
  }
  .mainscreen img {
    /* position: absolute; */
    max-height: 380px;
    max-width: 288px;
  }
}

/* .loc_icon {
  width: 28px;
  height: 16px;
}

.store_icon {
  width: 28px;
  height: 16px;
} */
