body {
  overflow-x: hidden;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 8px rgb(227, 227, 227);
	border-radius: 15px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 15px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 15px;
	-webkit-box-shadow: inset 0 0 8px rgb(227, 227, 227);
	background-color: #b3b3b3;
}
.modal-backdrop.show{
z-index: 1;
}
.img-text {
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
}
.title-padding {
  /* padding: 0 8rem; */
  padding-left: 8rem;
}
.btn-royalblue {
  background-color: var(--dark-blue);
  border: 1 px solid var(--dark-blue);
  height: 56px;
  min-width: 182px;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 700;
  border-radius: 0px;
}
.aspect-ration-home-carosel{
  aspect-ratio: 24/24;
}
.order-response-title{
  margin-top: 5%;
  /* max-width: 450px; */
  color: var(--royal-blue);
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;

}
.order-reponse-desc{
  margin-top: 2rem;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: center;
}

.banner-title {
  margin-top: 25%;
  max-width: 450px;
  color: var(--royal-blue);
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
}
.banner-desc {
  margin-top: 2rem;
  max-width: 340px;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}
.banner-contain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
}
.banner-contain div {
  width: 100%;
}

.shopnowbtn {
  background-color: var(--royal-blue);
  margin-top: 3rem;
}
/* bannercarosel start */

.Tarrow {
  width: 9px;
  height: 16px;
}

.home-banner {
  /* height: 690px; */
  height: 690px;
  width: 100%;
  border-radius: 0px;
}
.home-banner img {
  height: 100%;
  width: 100%;
  /* height: 690px; */
  height: 690px;
  /* width: 720px; */
  width: -webkit-fill-available;
}
.latesttrend-btn {
  background-color: #ffffff;
  border-bottom-left-radius: 5rem;
  border-top-left-radius: 5rem;
  border-right: 0;
  opacity: 0.8;
  /* bottom: 330px; */
}

.review-btn {
  background-color: #ffffff;
  border-bottom-left-radius: 5rem;
  border-top-left-radius: 5rem;
  border-right: 0;
  opacity: .8;
  bottom: 220px;
}
.caroselBanner-btn {
  background-color: #1d1c1f;
  border-radius: 5rem;
  opacity: .8;
  width: 3rem;
  
}
.homecarosel-btn .nextbtn {
  position: absolute;
}
.homecarosel-btn .nextbtn {
  position: absolute;
  /* margin-left: px; */
}

.homecaroselbtn .slah {
  /* bottom: 0;
  position: absolute; */
}
.homecarosel-btn .mid-slah {
  margin-left: 5px;
  margin-right: 10px;
}
/* bannercarosel end */
/* .maincategoriesspace{
    margin-left: 1px;
    margin-right: 1px;
} */
.mainCategyCard-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.maincategories {
  /* position: relative; */
  background-size: cover;
  background-position: center;
  /* width: 265px; */
  height: 310px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-right: 2rem;
  left: 3px;
  top: 0px;
  padding-left: 5rem;
}
.maincategories .card-text {
  position: absolute;
  bottom: 40px;
  left: 35%;
}
.bestselling-bg {
  /* background: linear-gradient(white 30%, white 30%, #dcddde 30%); */
  background: -webkit-linear-gradient(180deg, #fff 38%, rgba(0, 0, 0, 0) 38%),
    -webkit-linear-gradient(180deg, #dcddde 83%, #fff 83%);
  background: -o-linear-gradient(180deg, #fff 38%, rgba(0, 0, 0, 0) 38%),
    -o-linear-gradient(180deg, #dcddde 83%, #fff 83%);
  background: -moz-linear-gradient(180deg, #fff 38%, rgba(0, 0, 0, 0) 38%),
    -moz-linear-gradient(180deg, #dcddde 83%, #fff 83%);
  background: linear-gradient(180deg, #fff 38%, rgba(0, 0, 0, 0) 38%),
    linear-gradient(180deg, #dcddde 83%, #fff 83%);
}
.bestselling {
  /* width: 580px; */
  /* height: 580px; */

  /* position: absolute; */
  left: 5%;
  bottom: 5%;
  background-size: cover;
  background-position: center;
}
.bestselling .smallcard {
  height: 285px;
  width: 285px;
}
.bestselling .card-text {
  /* position: absolute;
  bottom: 40px; */
  font-family: "DM Sans", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.bestselling .sub-card-text {
  color: white;
  /* position: absolute;
  bottom: 10px; */
  font-family: "DM Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.bestselling-number {
  font-size: 30px;
  /* position: absolute;
  top: 0; */
}
.bestsellcard {
  height: 275px;
  width: 275px;
  /* border-radius: 0px; */
  /* background-size: cover; */
  /* background-position: center; */
}
.trends-desc {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.Learn-more {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-align: left;
}
.Learn-more:hover {
  color: black;
}
/* customercard start */
.customercard {
  height: 401.1698913574219px;
  width: 454.2508850097656px;
  left: 135px;
  top: 3388.9150390625px;
  border-radius: 0px;
  background-color: var(--royal-blue);
  /* position:relative; */
}
.customercard p {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.customercard span {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.customercard img {
  height: 57.16415786743164px;
  width: 57.16415786743164px;
  left: 175.83154296875px;
  top: 3692.0892333984375px;
}

.customername p {
  bottom: 3rem;
  left: 3rem;
}
/* customer card end */

.register-card {
  min-height: 399px;
  width: 471px;
}

/* title */
.reviewtitle {
  width: 399px;
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}
.sub-title {
  color: var(--royal-blue);
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

.desktop-visible {
  display: contents;
}
.largescreen-visible {
  display: none;
}
.notebook-visible {
  display: none;
}
.notebooktwo-visible {
  display: none;
}
.tablet-visible {
  display: none;
}
.mob-visible {
  display: none;
}

@media screen and (max-width: 992px) {
  .np-tablet {
    padding: 0 !important;
    margin: 0 !important;
  }
  .home-banner {
    /* height: 690px; */
    height: 550px;
    width: 100%;
    border-radius: 0px;
  }
  .home-banner img {
    height: 550px;
    width: 100%;
    width: -webkit-fill-available;
  }
  .img-text {
    background-color: rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
  }
  .banner-title {
    margin-top: 25%;
    max-width: 450px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bestselling .card-text {
    font-family: "DM Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .sub-card-text {
    color: white;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bestselling-number {
    font-size: 25px;
    /* position: absolute;
    top: 0; */
  }
}
@media screen and (max-width: 768px) {
  .img-text {
    background-color: rgba(0, 0, 0, 0.1);
    width: 30px;
    height: 30px;
  }
  .sub-title {
    color: var(--royal-blue);
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
  }
  .banner-title {
    margin-top: 25%;
    max-width: 450px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }
  .banner-desc {
    margin-top: 0;
    max-width: 291px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }
  .bestselling-number {
    font-size: 20px;
  }
  .bestselling .card-text {
    font-family: "DM Sans", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .sub-card-text {
    color: white;
    /* position: absolute;
    bottom: 10px; */
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
  }
}
@media screen and (min-width: 575px) {
  .quick {
    display: none;
  }
  .latest {
    display: none;
  }
  .see-mob {
    display: none;
  }
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .review-btn {
    display: none;
  }
  .img-text {
    background-color: rgba(0, 0, 0, 0.1);
    width: 20px;
    height: 20px;
  }
  /* .register-card {
    height:100vh; */
  /* width: 100%;
  } */
  .np-mob {
    padding: 0 !important;
    margin: 0 !important;
  }
  .bestselling-bg {
    background: linear-gradient(white 40%, white 30%, #dcddde 30%);
  }

  .title-padding {
    padding: 0 1rem;
  }
  .banner-contain {
    /* display: flex; */
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    width: 100%;
  }
  .banner-contain div {
    width: 100%;
  }
  .banner-title {
    margin-top: 0;
    max-width: 284px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .banner-desc {
    margin-top: 0;
    max-width: 291px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }
  .shopnowbtn {
    background-color: var(--royal-blue);
    margin-top: 1rem;
  }
  /* bannercarosel start */
  .home-banner {
    height: 352px;
    width: 100%;
    left: 720px;
    top: 140px;
    border-radius: 0px;
  }
  .home-banner img {
    height: 100%;
    width: 100%;
    height: 352px;
    /* width: 720px; */
    width: -webkit-fill-available;
  }
  .bestsell {
    display: none;
  }
  .bestselling {
    /* width: 580px; */
    /* height: 580px; */

    position: absolute;
    left: 5%;
    bottom: 0;
    background-size: cover;
    background-position: center;
  }
  .bestsellcard {
    height: 151px;
    width: 151px;
    /* border-radius: 0px; */
    /* background-size: cover; */
    /* background-position: center; */
  }
  .bestselling-number {
    font-family: "DM Sans", sans-serif;
    position: unset;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .card-text {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .sub-card-text {
    color: white;
    font-family: "DM Sans", sans-serif;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1px;
    letter-spacing: 0em;
    text-align: left;
  }
  .maincategoriesspace {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .maincategories {
    height: auto;
    width: auto;
    border-radius: 0px;
  }
  .mainCategyCard-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mainCategoryImg {
    height: 203px;
    width: 151.006591796875px;
    border-radius: 0px;
  }
  .latest-trends {
    width: 334px;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }
  .trends-desc {
    width: 311px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
  .reviewtitle {
    width: 243px;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }
  .sub-title {
    color: var(--royal-blue);
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
  }
  /* customer card start */
  .customercard {
    height: 303px;
    width: 320px;
    left: 21px;
    top: 2122px;
    border-radius: 0px;

    background-color: var(--royal-blue);
  }
  .customercard p {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .customercard span {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .customercard img {
    height: 44px;
    width: 45px;
    left: 57px;
    top: 2336px;
  }
  /* customer card end */
}
