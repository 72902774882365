.desktop-slide {
  display: contents;
}
.mob-slide {
  display: none;
}
.minitablet-slide {
  display: none;
}
.tablet-slide {
  display: none;
}
.notebook-slide {
  display: none;
}
.RecommedationSlicker {
  /* width: 270px; */
  /* height: 280px; */
  aspect-ratio: 270/285;
}
.recommendationbg {
  background: linear-gradient(180deg, rgba(39, 65, 86, 0) 0%, #274156 100%);
  opacity: 0.5;
}
@media screen and (max-width: 1200px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: none;
  }
  .minitablet-slide {
    display: none;
  }
  .tablet-slide {
    display: none;
  }
  .notebook-slide {
    display: contents;
  }
}
@media screen and (max-width: 992px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: none;
  }
  .minitablet-slide {
    display: none;
  }
  .tablet-slide {
    display: contents;
  }
  .notebook-slide {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: none;
  }
  .minitablet-slide {
    display: contents;
  }
  .tablet-slide {
    display: none;
  }
  .notebook-slide {
    display: none;
  }
  .RecommedationSlicker {
    width: 153px;
    height: 162px;
    /* aspect-ratio: 153/162; */
  }
}
@media screen and (max-width: 576px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: contents;
  }
  .minitablet-slide {
    display: none;
  }
  .tablet-slide {
    display: none;
  }
  .notebook-slide {
    display: none;
  }
  .recommendationbg {
    background: linear-gradient(180deg, rgba(39, 65, 86, 0) 0%, #274156 100%);
    opacity: 0.5;
  }
  .RecommedationSlicker {
    width: 153px;
    height: 162px;
  }
}
