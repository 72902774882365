.input-border {
  border: 1px solid var(--royal-blue);
}
.input-border:focus {
  box-shadow: none;
  outline: none;
}
input {
  border: 1px solid var(--royal-blue);
  height: 3rem;
}
input:focus {
  box-shadow: none;
  outline: none;
}
.name-inpt {
  padding-right: 0;
}
.contact-para{
    font-size: 20px;
    line-height: 30px;
}
