#single-product-description  {
  color: #65635f;
  padding: 1rem 0;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
#single-product-description  ol{
padding: 0 0 0 1rem;
}
#single-product-description  ul{
padding: 0 0 0 1rem;
}
#single-product-description  li{
  color: #65635f;
  /* padding: 1rem 0; */
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
/* #single-product-description > div > p:nth-child(1) {
  
} */
#single-product-description > div > strong {
  padding: 1rem 0;
  font-size: large;
  line-height: 4rem;
}
#single-product-description > div > p > strong {
  padding: 1rem 0;
  font-size: large;
  line-height: 4rem;
}
#single-product-description > div > p {
  padding: 0;
  margin: 0;
  
}

/* #single-product-description > div > strong > u; */
