.mainCategoryCardImg {
  /* height: 310px; */
      /* width: 285px; */
    aspect-ratio: 285/310;
    } 
/* width: min-content; */

.mainCategyCard-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.hitstylee ul {
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  /* display: flex; */
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);

  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.hitstylee ul li {
  padding: 0;
  margin: 0;
  /* flex: 0 0 50%;
    max-width: 50%; */
  /* max-width: 33.333333%; */
  /* flex: 0 0 33.333333%; */
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  list-style: none;
  /* display: block; */
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}


@media screen and (max-width: 768px) {
    .mainCategoryCardImg {
      /* height: 200px; */
      /* width: 285px; */
    aspect-ratio: 285/310;

    } 

    .hitstylee ul {
      padding: 0;
      margin: 0;
      list-style: none;
      flex-wrap: wrap;
      /* display: flex; */
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      /* padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5); */
      margin-top: var(--bs-gutter-y);
    
      /* list-style-type: none;
      margin: auto;
      padding: auto;
      display: block; */
    }
    .hitstylee ul li {
      padding: 0;
      margin: 0;
      /* flex: 0 0 50%;
        max-width: 50%; */
      /* max-width: 33.333333%; */
      /* flex: 0 0 33.333333%; */
      flex: 0 0 100%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      list-style: none;
      /* display: block; */
      /* list-style-type: none;
      margin: auto;
      padding: auto;
      display: block; */
    }

  }
@media screen and (max-width: 576px) {
  .mainCategyCard-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mainCategoryCardImg {
    width: 100%;
    height: 203px;
    /* width: 151.006591796875px; */
    border-radius: 0px;
  }
}
