/* logo */
.logo {
  height: 40px;
  width: 120px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}

.desktop-container-lg{
  margin:0 5rem;
}

.menu-padding{
  padding-left: 40px  ;
}
.header-space{
  padding:0 149px;
}
.header-sub-link-parent div {
  border-right: 1px solid var(--dark-blue);
}
.header-sub-link-parent div:last-child {
  border-right: 0;
}
.search-hit-list ul li {
  list-style-type: none;
}
.logout-popup {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.logout-popup:hover {
  background-color: red;
  color: white;
}
.menu-btn:hover {
  outline: none;
  background-color: white;
  z-index: 200;
}
.menu-btn:focus {
  outline: none;
  background-color: white;
}
.mob-visible {
  display: none;
}
.overlay {
  background: rgba(39, 42, 43, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);

  overflow-y: hidden;
}
.header-link {
  padding-right: 1rem;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}
.header-sub-link-cart {
  padding-right: 20px;
  font-size: 17px ;
}
.header-sub-link-login {
  padding-left: 16px;
  font-size: 17px ;
}
.header .header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-container {
  margin: 0 0 0 8rem;
}
.header .links {
  position: absolute;
  left: 29%;
}
/* .header .links a{
    
} */

.header button div {
  text-align: center;
}
.profile-input {
  padding: 5px;
  border: none;
  border-bottom: 1px solid var(--royal-blue);
  margin-bottom: 20px;
  background-color: transparent;
  width: 100%;
}
.profile-input:focus {
  outline: none;
}
.profile-input::placeholder {
  color: black;
  font-weight: 700;
}

.links a {
  text-decoration: none;
  text-align: center;
  font-family: "Roboto", normal;
  color: black;
  /* padding-right: 10px; */
  margin-bottom: 10px;
  padding:0
}
.links a:last-child {
  border-right: none;
}
/* .links a:hover{
    color:#FAB62F;
    text-decoration: none;
} */

/* .links a :focus{
    color:#FAB62F;
    text-decoration: none;
} */
.search-resultborder {
  border: 1px solid rgb(29, 28, 31);
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.search-resulthits ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
/* //searchbar */
.searchbar {
  /* position: absolute; */
  /* right:13%; */
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 43px;
  width: 199px;
  border-radius: 5rem;
  background-color: #f5f5f5;
  /* background-image: url("../../component/assets/searchicn.png"); */
  background-position: calc(100% - 10px) calc(0.8em),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 15px;
  background-repeat: no-repeat;
}
.search-border {
  border: 1px solid black;
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.search-result {
  height: auto;
  width: 199px;
}
.searchbar input {
  border: none;
  height: 41px;
  bottom: 5px;
  border-radius: 5rem 0 0 5rem;
  background-color: #f5f5f5;
  padding: 16px;
  width: 80%;
}
.searchbar button {
  background-color: #f5f5f5;
  background-color: transparent;
  border: transparent;
  /* background-image:url('../assets/searchicon.png'); */
  /* display: none; */
}
.searchbar button:hover {
  background-color: #f5f5f5;
}
.searchbar input :focus {
  border: none;
  text-decoration: none;
}

.searchbaricon {
  /* height:2rem;
  width:2rem; */
  padding-right: 5rem;
}
.searchinput {
  border: none;
  /* border-radius:5rem; */
  background-color: #f5f5f5;
  padding: 16px;
  width: 100%;

  /* height: 100%; */
  /* outline:none; */
}
.searchinput:focus {
  box-shadow: none;
  outline: none;
}
.searchinput::placeholder {
  /* color:$secondary-font; */
  font-weight: 300;
}
.checkout-btn {
  background-color: black;
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  height: 48px;
  color: white;
}
.price-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.02em;
}
/* .cartpop{
  max-width: 599px;

} */
.carts-icon {
  width: 30px;
  height: 30px;
  background-color: var(--royal-blue);
}
.cart-qty {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
}
.cart-pos {
  text-align: center;
  content: attr(data-count);
  position: absolute;
  background: #ff6600;
  height: 1.2rem;
  top: -12px;
  right: 6px;
  width: 1.2rem;
  border-radius: 50%;
  color: white;
  border: 1px solid #ff6600;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
}

/* @media screen and (min-width: 992px) {
  .logo {
    height: 39px;
    width: 114px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
} */
@media screen and (max-width: 1024px) {
  
}
@media screen and (max-width: 1200px) {
  .header-link {
    padding-right: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  
  .header-sub-link-cart {
    padding-right: 18px;
    font-size: 14px;
  }
  .header-sub-link-login {
    padding-left: 10px !important ;
    font-size: 14px;
  }

  .logo {
    height: 40px;
    width: 120px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
  .searchbar {
    /* position: absolute; */
    /* right:13%; */
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 43px;
    width: 169px;
    border-radius: 5rem;
    background-color: #f5f5f5;
    /* background-image: url("../../component/assets/searchicn.png"); */
    background-position: calc(100% - 10px) calc(0.8em),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 992px) {
  .desktop-container-lg{
    margin:0 0rem;
  }
  .cart-pos {
    text-align: center;
    content: attr(data-count);
    position: absolute;
    background: #ff6600;
    height: 1.2rem;
    top: -12px;
    right: -8px;
    width: 1.2rem;
    border-radius: 50%;
    color: white;
    border: 1px solid #ff6600;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 12px;
  }

  .header-link {
    padding-right: 3rem;
    font-size: 14px;
  }

  .searchbar {
    /* position: absolute; */
    /* right:13%; */
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 43px;
    width: 175px;
    border-radius: 5rem;
    background-color: #f5f5f5;
    /* background-image: url("../../component/assets/searchicn.png"); */
    background-position: calc(100% - 10px) calc(0.8em),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  .search-result {
    height: auto;
    width: 175px;
  }
  .searchbar input {
    border: none;
    height: 41px;
    bottom: 5px;
    border-radius: 5rem 0 0 5rem;
    background-color: #f5f5f5;
    padding: 16px;
    width: 70%;
  }
  .searchbar button {
    background-color: #f5f5f5;
    background-color: transparent;
    border: transparent;
    /* background-image:url('../assets/searchicon.png'); */
    /* display: none; */
  }
  .searchbar button:hover {
    background-color: #f5f5f5;
  }
  .searchbar input :focus {
    border: none;
    text-decoration: none;
  }
  .price-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
}
@media screen and (max-width: 768px) {
  .header-space{
    padding:0 0;
  }
  .menu-padding{
    padding:0;
  }
  .cart-qty {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.03em;
  }
  .header-link {
    padding-right: 3rem;
    font-size: 12px;
  }

  .searchbar {
    /* position: absolute; */
    /* right:13%; */
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 43px;
    width: 135px;
    border-radius: 5rem;
    background-color: #f5f5f5;
    /* background-image: url("../../component/assets/searchicn.png"); */
    background-position: calc(100% - 10px) calc(0.8em),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }
  .search-result {
    height: auto;
    width: 135px;
  }
  .searchbar input {
    border: none;
    height: 41px;
    bottom: 5px;
    border-radius: 5rem 0 0 5rem;
    background-color: #f5f5f5;
    padding: 16px;
    width: 63%;
  }
  .searchbar button {
    background-color: #f5f5f5;
    background-color: transparent;
    border: transparent;
    /* background-image:url('../assets/searchicon.png'); */
    /* display: none; */
  }
  .searchbar button:hover {
    background-color: #f5f5f5;
  }
  .searchbar input :focus {
    border: none;
    text-decoration: none;
  }

  .price-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
}
@media screen and (min-width: 992px) {
  .header-mob {
    display: none;
  }

  .menus {
    display: none;
  }
  /* .logo {
    height: 25px;
    width: 74px;
    left: 150px;
    top: 17px;
    border-radius: 0px;
  } */
}
@media screen and (max-width: 992px) {
  .nav-tablet-padding {
    padding: 0 2.5rem !important;
  }
  .logo {
    height: 40px;
    width: 120px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
  .mob-profile {
    padding-left: 0.75rem;
  }
  .nav-mob-padding {
    padding-left: 2rem;
  }
  .nav-mob-padding li {
    padding: 0 0 1rem 1rem;
  }
  .header-mob-position {
    top: 0;
    position: fixed;
    z-index: 50;
    width: -webkit-fill-available;
  }

  .navbar-collapse {
    position: fixed;
    top: 50px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 80%;
    height: 100%;
    background-color: white;
    z-index: 1;
    padding-top: 1rem;
  }
  .navbar-brand {
    position: relative;
    z-index: 100;
  }
  .navbar-collapse.collapsing {
    left: -80%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
    z-index: 99;
  }
  .navbar-toggler {
    box-shadow: none !important;
  }
  .navbar-toggler div {
    height: 3px;
    border-radius: 2px;
    background: rgb(56 56 56);
    margin: 4px;
    transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;
  }
  .navbar-toggler.navbar-toggler-active {
    transform: scale(0.7) rotate(45deg);
    transition: all 0.25s ease 0.05s;
    margin: 0px;
    padding: 4px;
  }

  .navbar-toggler.navbar-toggler-active div {
    margin: 10px;
  }
  .navbar-toggler.navbar-toggler-active div:first-child {
    transform: translateY(13px) rotate(0deg);
  }
  .navbar-toggler.navbar-toggler-active div:nth-child(2) {
    opacity: 0;
  }
  .navbar-toggler.navbar-toggler-active div:last-child {
    transform: translateY(-13px) rotate(90deg);
  }

  .navbar-toggler div:first-child {
    width: 28px;
  }
  .navbar-toggler div:last-child {
    width: 28px;
  }
  .navbar-toggler div:nth-child(2) {
    width: 28px;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 500ms ease-in-out;
  }
  .mob-nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .header {
    display: none;
  }
  .header-mob {
    padding: 5% 10px !important;
  }
  .donated {
    width: 380px;
  }
  .search-resultcont {
    display: none;
  }
  .menus {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-color: white;
    width: 100%;
    z-index: 50;
  }
  .menus a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-family: "Roboto", normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;
    color: white;
    margin-bottom: 30px;
  }
  .menus a:first-child {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .header-mob .search {
    height: 18px;
    width: 21px;
    margin-top: 2%;
  }
  .header-mob {
    height: min-content;
  }
  .mob-logo {
    height: 25px;
    width: 74px;
    /* left: 150px; */
    top: 11px;
    border-radius: 0px;
  }
  .quick {
    background-color: white;
  }
  .tabs {
    margin-top: 5px;
    height: 18px;
    width: 21px;
    /* filter: brightness(0) invert(1); */
  }
  .mobile-menu {
    /* position: absolute; */
    height: 14px;
    width: 20px;
    /* margin-left: 30px; */
    top: 26px;
  }

  .menu-option a {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  .menu-option label {
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
  }
  .close-btn {
    width: 20px;
    height: 20px;
    /* left: 10px; */
    top: 26px;
  }
  .mob-visible {
    display: contents;
  }
}

/* .menu-option {
  display: flex;
  align-items: center;
} */

.row > * {
  padding-left: 0;
}

@media screen and (max-width: 575px) {
  .nav-tablet-padding {
    padding: 0 0 !important;
  }
}
