@media screen and (max-width: 575px) {
    .delete-add-desk{
        display: none;
    }

    .address-list-item .btns{
        flex-direction: column;
        align-items: flex-end;
    }
}

@media screen and (min-width: 575px) {
    .delete-add-mob{
        display: none;
    }
    .address-list-item button:first-child{
        margin-right: 10px;
    }
    
}


.delete-add-mob img{
    height: 15px;
    margin-bottom: 40px;
}

.address-list-item button{
    padding: 10px 25px;
    font-size: 0.8rem;
}


.profile-menu-item img{
    height: 20px;
}

.profile-menu-item p{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 18px; */
    letter-spacing: 0px;
    text-align: left;
    font-family: CircularStd;

}

.profile-menu-item{
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid  var(--grey);
}

.profile-edit .accordion__button{
    background-color: white;
    border: none;
}

.profile-edit .accordion__panel{
    padding: 15px;
}

.profile-edit-contianer{
    margin-top: 5%;
}

.profile-edit .accordion__button{
    padding: 10px 15px 10px 15px;
    font-size: 0.85rem;
    font-weight: 900;
}

.profile-edit .accordion__item + .accordion__item{
    border-top: none;
    border-bottom: 1px solid  var(--grey);
}

.accordion__button:focus{
    outline: none;
}

.small-normal{
    /* font-weight: 900; */
    font-size: 0.9rem;
    font-family: CircularStd;
    font-weight: 400;
}

.small-bold{
    font-weight: 900;
    font-size: 1rem;
    font-family: CircularStd;
}


.profile-input{
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px 0;
    border-bottom: 1px solid  var(--grey);
    margin-bottom: 20px;
}

.profile-input::-webkit-input-placeholder { /* Edge */
    font-size: 0.8rem;
    color: var(--blackmain);
    font-weight: 900;
    font-family: CircularStd;
  }
  
  .profile-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 0.8rem;
    font-weight: 900;
    color: var(--blackmain);
    font-family: CircularStd;
  }
  
  .profile-input::placeholder {
    font-weight: 900;
    font-size: 0.8rem;
    color: var(--blackmain);
    font-family: CircularStd;
  }

  #personal_data button{
      width: 100%;
      font-size: 0.8rem;
  }


  .profile-header{
    display: flex;
    align-items: center;
    margin-top: 5%;

  }

  .profile-header img{
    height: 20px;
    transform: rotate(180deg);
  }


  /* ADDRESS LIST */
.address-list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid  var(--grey);

}

.shipping-list-container{
    margin-top: 5%;
    margin-bottom: 15%;
}

.address-list-item p{
    width: 40%;
}

.address-list-item .btns{
    display: flex;
}

.absolute-btn{
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding-bottom: 10px;
}