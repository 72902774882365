@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-sub-link-parent div {
  border-right: 1px solid white;
  padding:0 15px
}

.footer-sub-link-parent div:last-child {
  border-right: 0;
}
.footer {
  background-color: var(--royal-blue);
  overflow: hidden;
  color: white;
}
.footer  a{
  text-decoration: none !important;
  font-family: Roboto;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.footer span{
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  display: block;
  /* text-align: left; */
  
}

.footer a:hover{
    color:#fff;
    text-decoration: none !important;
    border-bottom:1px solid #fff
}

.footer a :focus{
    color:#fff;
    text-decoration: none !important;
    border-bottom:1px solid #fff

}

.down-footer {
  height: 330px;
  display: flex;
  flex-direction: row;
}

.tags {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: auto;
  width: 30%;
  padding-right: 0.9rem !important;
}
.first-down {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3.3rem;
  padding-bottom: 3rem;
}
.singletag {
  margin-right: 5rem !important;
  white-space: nowrap;
}
.tags a {
  font-family: "Roboto", normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none !important;
  color: #fff;
  border-right: none;
}
.subtag {
  display: flex;
  flex-direction: column;
}
.subtag a {
  font-family: "Roboto", normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
}
.endfooter {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
}
.copy {
  font-family: Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: center;
  padding-left: 6%;
}
.follow {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 0;
  flex-direction: row;
  font-family: Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: right;
  padding-right: 4.9rem !important;
}
.follow span {
  margin-right: 10px;
}
.social {
  height: 29px;
  /* margin-right: 10px; */
}
.yt {
  height: 29px;
  margin-right: 10px;
}
.social img :first-child {
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .footer span{
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    display: block;
    /* text-align: left; */
    
  }
}
@media screen and (max-width: 768px) {
  .social {
    height: 16px;
    margin-right: 3px;
  }
}
@media screen and (min-width: 575px) {
  .down-mob-footer {
    display: none !important;
  }
}
@media screen and (max-width: 575px) {
  .social {
    height: 23px;
    margin-right: 3px;
  }
  .downfooter {
    display: none;
  }
  .sprt-footer {
    display: flex;
  }
  .down-mob-footer .footerlogo {
    text-align: center;
    width: 100%;
    padding-left: 18%;
    margin-left: auto;
    margin-right: auto;
  }
  .sprt-btn {
    display: flex;
    justify-content: center;
  }
  .support {
    font-size: 28px !important;
    display: flex;
    justify-content: center;
  }

  .contain {
    /* padding: 0 !important;c */
  }
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 8px rgb(227, 227, 227);
	border-radius: 15px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 15px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 15px;
	-webkit-box-shadow: inset 0 0 8px rgb(227, 227, 227);
	background-color: #b3b3b3;
}
.modal-backdrop.show{
z-index: 1;
}
.img-text {
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
}
.title-padding {
  /* padding: 0 8rem; */
  padding-left: 8rem;
}
.btn-royalblue {
  background-color: var(--dark-blue);
  border: 1 px solid var(--dark-blue);
  height: 56px;
  min-width: 182px;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 700;
  border-radius: 0px;
}
.aspect-ration-home-carosel{
  aspect-ratio: 24/24;
}
.order-response-title{
  margin-top: 5%;
  /* max-width: 450px; */
  color: var(--royal-blue);
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;

}
.order-reponse-desc{
  margin-top: 2rem;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: center;
}

.banner-title {
  margin-top: 25%;
  max-width: 450px;
  color: var(--royal-blue);
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
}
.banner-desc {
  margin-top: 2rem;
  max-width: 340px;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}
.banner-contain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
}
.banner-contain div {
  width: 100%;
}

.shopnowbtn {
  background-color: var(--royal-blue);
  margin-top: 3rem;
}
/* bannercarosel start */

.Tarrow {
  width: 9px;
  height: 16px;
}

.home-banner {
  /* height: 690px; */
  height: 690px;
  width: 100%;
  border-radius: 0px;
}
.home-banner img {
  height: 100%;
  width: 100%;
  /* height: 690px; */
  height: 690px;
  /* width: 720px; */
  width: -webkit-fill-available;
}
.latesttrend-btn {
  background-color: #ffffff;
  border-bottom-left-radius: 5rem;
  border-top-left-radius: 5rem;
  border-right: 0;
  opacity: 0.8;
  /* bottom: 330px; */
}

.review-btn {
  background-color: #ffffff;
  border-bottom-left-radius: 5rem;
  border-top-left-radius: 5rem;
  border-right: 0;
  opacity: .8;
  bottom: 220px;
}
.caroselBanner-btn {
  background-color: #1d1c1f;
  border-radius: 5rem;
  opacity: .8;
  width: 3rem;
  
}
.homecarosel-btn .nextbtn {
  position: absolute;
}
.homecarosel-btn .nextbtn {
  position: absolute;
  /* margin-left: px; */
}

.homecaroselbtn .slah {
  /* bottom: 0;
  position: absolute; */
}
.homecarosel-btn .mid-slah {
  margin-left: 5px;
  margin-right: 10px;
}
/* bannercarosel end */
/* .maincategoriesspace{
    margin-left: 1px;
    margin-right: 1px;
} */
.mainCategyCard-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.maincategories {
  /* position: relative; */
  background-size: cover;
  background-position: center;
  /* width: 265px; */
  height: 310px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-right: 2rem;
  left: 3px;
  top: 0px;
  padding-left: 5rem;
}
.maincategories .card-text {
  position: absolute;
  bottom: 40px;
  left: 35%;
}
.bestselling-bg {
  /* background: linear-gradient(white 30%, white 30%, #dcddde 30%); */
  background: linear-gradient(180deg, #fff 38%, rgba(0, 0, 0, 0) 38%),
    linear-gradient(180deg, #dcddde 83%, #fff 83%);
}
.bestselling {
  /* width: 580px; */
  /* height: 580px; */

  /* position: absolute; */
  left: 5%;
  bottom: 5%;
  background-size: cover;
  background-position: center;
}
.bestselling .smallcard {
  height: 285px;
  width: 285px;
}
.bestselling .card-text {
  /* position: absolute;
  bottom: 40px; */
  font-family: "DM Sans", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.bestselling .sub-card-text {
  color: white;
  /* position: absolute;
  bottom: 10px; */
  font-family: "DM Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.bestselling-number {
  font-size: 30px;
  /* position: absolute;
  top: 0; */
}
.bestsellcard {
  height: 275px;
  width: 275px;
  /* border-radius: 0px; */
  /* background-size: cover; */
  /* background-position: center; */
}
.trends-desc {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.Learn-more {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-align: left;
}
.Learn-more:hover {
  color: black;
}
/* customercard start */
.customercard {
  height: 401.1698913574219px;
  width: 454.2508850097656px;
  left: 135px;
  top: 3388.9150390625px;
  border-radius: 0px;
  background-color: var(--royal-blue);
  /* position:relative; */
}
.customercard p {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.customercard span {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.customercard img {
  height: 57.16415786743164px;
  width: 57.16415786743164px;
  left: 175.83154296875px;
  top: 3692.0892333984375px;
}

.customername p {
  bottom: 3rem;
  left: 3rem;
}
/* customer card end */

.register-card {
  min-height: 399px;
  width: 471px;
}

/* title */
.reviewtitle {
  width: 399px;
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}
.sub-title {
  color: var(--royal-blue);
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

.desktop-visible {
  display: contents;
}
.largescreen-visible {
  display: none;
}
.notebook-visible {
  display: none;
}
.notebooktwo-visible {
  display: none;
}
.tablet-visible {
  display: none;
}
.mob-visible {
  display: none;
}

@media screen and (max-width: 992px) {
  .np-tablet {
    padding: 0 !important;
    margin: 0 !important;
  }
  .home-banner {
    /* height: 690px; */
    height: 550px;
    width: 100%;
    border-radius: 0px;
  }
  .home-banner img {
    height: 550px;
    width: 100%;
    width: -webkit-fill-available;
  }
  .img-text {
    background-color: rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
  }
  .banner-title {
    margin-top: 25%;
    max-width: 450px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bestselling .card-text {
    font-family: "DM Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .sub-card-text {
    color: white;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bestselling-number {
    font-size: 25px;
    /* position: absolute;
    top: 0; */
  }
}
@media screen and (max-width: 768px) {
  .img-text {
    background-color: rgba(0, 0, 0, 0.1);
    width: 30px;
    height: 30px;
  }
  .sub-title {
    color: var(--royal-blue);
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
  }
  .banner-title {
    margin-top: 25%;
    max-width: 450px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }
  .banner-desc {
    margin-top: 0;
    max-width: 291px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }
  .bestselling-number {
    font-size: 20px;
  }
  .bestselling .card-text {
    font-family: "DM Sans", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .sub-card-text {
    color: white;
    /* position: absolute;
    bottom: 10px; */
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
  }
}
@media screen and (min-width: 575px) {
  .quick {
    display: none;
  }
  .latest {
    display: none;
  }
  .see-mob {
    display: none;
  }
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .review-btn {
    display: none;
  }
  .img-text {
    background-color: rgba(0, 0, 0, 0.1);
    width: 20px;
    height: 20px;
  }
  /* .register-card {
    height:100vh; */
  /* width: 100%;
  } */
  .np-mob {
    padding: 0 !important;
    margin: 0 !important;
  }
  .bestselling-bg {
    background: linear-gradient(white 40%, white 30%, #dcddde 30%);
  }

  .title-padding {
    padding: 0 1rem;
  }
  .banner-contain {
    /* display: flex; */
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    width: 100%;
  }
  .banner-contain div {
    width: 100%;
  }
  .banner-title {
    margin-top: 0;
    max-width: 284px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .banner-desc {
    margin-top: 0;
    max-width: 291px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }
  .shopnowbtn {
    background-color: var(--royal-blue);
    margin-top: 1rem;
  }
  /* bannercarosel start */
  .home-banner {
    height: 352px;
    width: 100%;
    left: 720px;
    top: 140px;
    border-radius: 0px;
  }
  .home-banner img {
    height: 100%;
    width: 100%;
    height: 352px;
    /* width: 720px; */
    width: -webkit-fill-available;
  }
  .bestsell {
    display: none;
  }
  .bestselling {
    /* width: 580px; */
    /* height: 580px; */

    position: absolute;
    left: 5%;
    bottom: 0;
    background-size: cover;
    background-position: center;
  }
  .bestsellcard {
    height: 151px;
    width: 151px;
    /* border-radius: 0px; */
    /* background-size: cover; */
    /* background-position: center; */
  }
  .bestselling-number {
    font-family: "DM Sans", sans-serif;
    position: unset;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .card-text {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .bestselling .sub-card-text {
    color: white;
    font-family: "DM Sans", sans-serif;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1px;
    letter-spacing: 0em;
    text-align: left;
  }
  .maincategoriesspace {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .maincategories {
    height: auto;
    width: auto;
    border-radius: 0px;
  }
  .mainCategyCard-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mainCategoryImg {
    height: 203px;
    width: 151.006591796875px;
    border-radius: 0px;
  }
  .latest-trends {
    width: 334px;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }
  .trends-desc {
    width: 311px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
  .reviewtitle {
    width: 243px;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }
  .sub-title {
    color: var(--royal-blue);
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
  }
  /* customer card start */
  .customercard {
    height: 303px;
    width: 320px;
    left: 21px;
    top: 2122px;
    border-radius: 0px;

    background-color: var(--royal-blue);
  }
  .customercard p {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .customercard span {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .customercard img {
    height: 44px;
    width: 45px;
    left: 57px;
    top: 2336px;
  }
  /* customer card end */
}

.loading-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000066;
    /* display: flex; */
    
    /* justify-content: center;
    align-items: center; */
    z-index: 99999;
}
/* logo */
.logo {
  height: 40px;
  width: 120px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}

.desktop-container-lg{
  margin:0 5rem;
}

.menu-padding{
  padding-left: 40px  ;
}
.header-space{
  padding:0 149px;
}
.header-sub-link-parent div {
  border-right: 1px solid var(--dark-blue);
}
.header-sub-link-parent div:last-child {
  border-right: 0;
}
.search-hit-list ul li {
  list-style-type: none;
}
.logout-popup {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.logout-popup:hover {
  background-color: red;
  color: white;
}
.menu-btn:hover {
  outline: none;
  background-color: white;
  z-index: 200;
}
.menu-btn:focus {
  outline: none;
  background-color: white;
}
.mob-visible {
  display: none;
}
.overlay {
  background: rgba(39, 42, 43, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);

  overflow-y: hidden;
}
.header-link {
  padding-right: 1rem;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}
.header-sub-link-cart {
  padding-right: 20px;
  font-size: 17px ;
}
.header-sub-link-login {
  padding-left: 16px;
  font-size: 17px ;
}
.header .header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-container {
  margin: 0 0 0 8rem;
}
.header .links {
  position: absolute;
  left: 29%;
}
/* .header .links a{
    
} */

.header button div {
  text-align: center;
}
.profile-input {
  padding: 5px;
  border: none;
  border-bottom: 1px solid var(--royal-blue);
  margin-bottom: 20px;
  background-color: transparent;
  width: 100%;
}
.profile-input:focus {
  outline: none;
}
.profile-input::-webkit-input-placeholder {
  color: black;
  font-weight: 700;
}
.profile-input::placeholder {
  color: black;
  font-weight: 700;
}

.links a {
  text-decoration: none;
  text-align: center;
  font-family: "Roboto", normal;
  color: black;
  /* padding-right: 10px; */
  margin-bottom: 10px;
  padding:0
}
.links a:last-child {
  border-right: none;
}
/* .links a:hover{
    color:#FAB62F;
    text-decoration: none;
} */

/* .links a :focus{
    color:#FAB62F;
    text-decoration: none;
} */
.search-resultborder {
  border: 1px solid rgb(29, 28, 31);
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.search-resulthits ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
/* //searchbar */
.searchbar {
  /* position: absolute; */
  /* right:13%; */
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 43px;
  width: 199px;
  border-radius: 5rem;
  background-color: #f5f5f5;
  /* background-image: url("../../component/assets/searchicn.png"); */
  background-position: calc(100% - 10px) calc(0.8em),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 15px;
  background-repeat: no-repeat;
}
.search-border {
  border: 1px solid black;
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.search-result {
  height: auto;
  width: 199px;
}
.searchbar input {
  border: none;
  height: 41px;
  bottom: 5px;
  border-radius: 5rem 0 0 5rem;
  background-color: #f5f5f5;
  padding: 16px;
  width: 80%;
}
.searchbar button {
  background-color: #f5f5f5;
  background-color: transparent;
  border: transparent;
  /* background-image:url('../assets/searchicon.png'); */
  /* display: none; */
}
.searchbar button:hover {
  background-color: #f5f5f5;
}
.searchbar input :focus {
  border: none;
  text-decoration: none;
}

.searchbaricon {
  /* height:2rem;
  width:2rem; */
  padding-right: 5rem;
}
.searchinput {
  border: none;
  /* border-radius:5rem; */
  background-color: #f5f5f5;
  padding: 16px;
  width: 100%;

  /* height: 100%; */
  /* outline:none; */
}
.searchinput:focus {
  box-shadow: none;
  outline: none;
}
.searchinput::-webkit-input-placeholder {
  /* color:$secondary-font; */
  font-weight: 300;
}
.searchinput::placeholder {
  /* color:$secondary-font; */
  font-weight: 300;
}
.checkout-btn {
  background-color: black;
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  height: 48px;
  color: white;
}
.price-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.02em;
}
/* .cartpop{
  max-width: 599px;

} */
.carts-icon {
  width: 30px;
  height: 30px;
  background-color: var(--royal-blue);
}
.cart-qty {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
}
.cart-pos {
  text-align: center;
  content: attr(data-count);
  position: absolute;
  background: #ff6600;
  height: 1.2rem;
  top: -12px;
  right: 6px;
  width: 1.2rem;
  border-radius: 50%;
  color: white;
  border: 1px solid #ff6600;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
}

/* @media screen and (min-width: 992px) {
  .logo {
    height: 39px;
    width: 114px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
} */
@media screen and (max-width: 1024px) {
  
}
@media screen and (max-width: 1200px) {
  .header-link {
    padding-right: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  
  .header-sub-link-cart {
    padding-right: 18px;
    font-size: 14px;
  }
  .header-sub-link-login {
    padding-left: 10px !important ;
    font-size: 14px;
  }

  .logo {
    height: 40px;
    width: 120px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
  .searchbar {
    /* position: absolute; */
    /* right:13%; */
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 43px;
    width: 169px;
    border-radius: 5rem;
    background-color: #f5f5f5;
    /* background-image: url("../../component/assets/searchicn.png"); */
    background-position: calc(100% - 10px) calc(0.8em),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 992px) {
  .desktop-container-lg{
    margin:0 0rem;
  }
  .cart-pos {
    text-align: center;
    content: attr(data-count);
    position: absolute;
    background: #ff6600;
    height: 1.2rem;
    top: -12px;
    right: -8px;
    width: 1.2rem;
    border-radius: 50%;
    color: white;
    border: 1px solid #ff6600;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 12px;
  }

  .header-link {
    padding-right: 3rem;
    font-size: 14px;
  }

  .searchbar {
    /* position: absolute; */
    /* right:13%; */
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 43px;
    width: 175px;
    border-radius: 5rem;
    background-color: #f5f5f5;
    /* background-image: url("../../component/assets/searchicn.png"); */
    background-position: calc(100% - 10px) calc(0.8em),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  .search-result {
    height: auto;
    width: 175px;
  }
  .searchbar input {
    border: none;
    height: 41px;
    bottom: 5px;
    border-radius: 5rem 0 0 5rem;
    background-color: #f5f5f5;
    padding: 16px;
    width: 70%;
  }
  .searchbar button {
    background-color: #f5f5f5;
    background-color: transparent;
    border: transparent;
    /* background-image:url('../assets/searchicon.png'); */
    /* display: none; */
  }
  .searchbar button:hover {
    background-color: #f5f5f5;
  }
  .searchbar input :focus {
    border: none;
    text-decoration: none;
  }
  .price-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
}
@media screen and (max-width: 768px) {
  .header-space{
    padding:0 0;
  }
  .menu-padding{
    padding:0;
  }
  .cart-qty {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.03em;
  }
  .header-link {
    padding-right: 3rem;
    font-size: 12px;
  }

  .searchbar {
    /* position: absolute; */
    /* right:13%; */
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 43px;
    width: 135px;
    border-radius: 5rem;
    background-color: #f5f5f5;
    /* background-image: url("../../component/assets/searchicn.png"); */
    background-position: calc(100% - 10px) calc(0.8em),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }
  .search-result {
    height: auto;
    width: 135px;
  }
  .searchbar input {
    border: none;
    height: 41px;
    bottom: 5px;
    border-radius: 5rem 0 0 5rem;
    background-color: #f5f5f5;
    padding: 16px;
    width: 63%;
  }
  .searchbar button {
    background-color: #f5f5f5;
    background-color: transparent;
    border: transparent;
    /* background-image:url('../assets/searchicon.png'); */
    /* display: none; */
  }
  .searchbar button:hover {
    background-color: #f5f5f5;
  }
  .searchbar input :focus {
    border: none;
    text-decoration: none;
  }

  .price-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
}
@media screen and (min-width: 992px) {
  .header-mob {
    display: none;
  }

  .menus {
    display: none;
  }
  /* .logo {
    height: 25px;
    width: 74px;
    left: 150px;
    top: 17px;
    border-radius: 0px;
  } */
}
@media screen and (max-width: 992px) {
  .nav-tablet-padding {
    padding: 0 2.5rem !important;
  }
  .logo {
    height: 40px;
    width: 120px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
  .mob-profile {
    padding-left: 0.75rem;
  }
  .nav-mob-padding {
    padding-left: 2rem;
  }
  .nav-mob-padding li {
    padding: 0 0 1rem 1rem;
  }
  .header-mob-position {
    top: 0;
    position: fixed;
    z-index: 50;
    width: -webkit-fill-available;
  }

  .navbar-collapse {
    position: fixed;
    top: 50px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 80%;
    height: 100%;
    background-color: white;
    z-index: 1;
    padding-top: 1rem;
  }
  .navbar-brand {
    position: relative;
    z-index: 100;
  }
  .navbar-collapse.collapsing {
    left: -80%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
    z-index: 99;
  }
  .navbar-toggler {
    box-shadow: none !important;
  }
  .navbar-toggler div {
    height: 3px;
    border-radius: 2px;
    background: rgb(56 56 56);
    margin: 4px;
    transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;
  }
  .navbar-toggler.navbar-toggler-active {
    -webkit-transform: scale(0.7) rotate(45deg);
            transform: scale(0.7) rotate(45deg);
    transition: all 0.25s ease 0.05s;
    margin: 0px;
    padding: 4px;
  }

  .navbar-toggler.navbar-toggler-active div {
    margin: 10px;
  }
  .navbar-toggler.navbar-toggler-active div:first-child {
    -webkit-transform: translateY(13px) rotate(0deg);
            transform: translateY(13px) rotate(0deg);
  }
  .navbar-toggler.navbar-toggler-active div:nth-child(2) {
    opacity: 0;
  }
  .navbar-toggler.navbar-toggler-active div:last-child {
    -webkit-transform: translateY(-13px) rotate(90deg);
            transform: translateY(-13px) rotate(90deg);
  }

  .navbar-toggler div:first-child {
    width: 28px;
  }
  .navbar-toggler div:last-child {
    width: 28px;
  }
  .navbar-toggler div:nth-child(2) {
    width: 28px;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 500ms ease-in-out;
  }
  .mob-nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .header {
    display: none;
  }
  .header-mob {
    padding: 5% 10px !important;
  }
  .donated {
    width: 380px;
  }
  .search-resultcont {
    display: none;
  }
  .menus {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-color: white;
    width: 100%;
    z-index: 50;
  }
  .menus a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-family: "Roboto", normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;
    color: white;
    margin-bottom: 30px;
  }
  .menus a:first-child {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .header-mob .search {
    height: 18px;
    width: 21px;
    margin-top: 2%;
  }
  .header-mob {
    height: -webkit-min-content;
    height: min-content;
  }
  .mob-logo {
    height: 25px;
    width: 74px;
    /* left: 150px; */
    top: 11px;
    border-radius: 0px;
  }
  .quick {
    background-color: white;
  }
  .tabs {
    margin-top: 5px;
    height: 18px;
    width: 21px;
    /* filter: brightness(0) invert(1); */
  }
  .mobile-menu {
    /* position: absolute; */
    height: 14px;
    width: 20px;
    /* margin-left: 30px; */
    top: 26px;
  }

  .menu-option a {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  .menu-option label {
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
  }
  .close-btn {
    width: 20px;
    height: 20px;
    /* left: 10px; */
    top: 26px;
  }
  .mob-visible {
    display: contents;
  }
}

/* .menu-option {
  display: flex;
  align-items: center;
} */

.row > * {
  padding-left: 0;
}

@media screen and (max-width: 575px) {
  .nav-tablet-padding {
    padding: 0 0 !important;
  }
}

.mainCategoryCardImg {
  /* height: 310px; */
      /* width: 285px; */
    aspect-ratio: 285/310;
    } 
/* width: min-content; */

.mainCategyCard-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.hitstylee ul {
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  /* display: flex; */
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);

  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.hitstylee ul li {
  padding: 0;
  margin: 0;
  /* flex: 0 0 50%;
    max-width: 50%; */
  /* max-width: 33.333333%; */
  /* flex: 0 0 33.333333%; */
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  list-style: none;
  /* display: block; */
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}


@media screen and (max-width: 768px) {
    .mainCategoryCardImg {
      /* height: 200px; */
      /* width: 285px; */
    aspect-ratio: 285/310;

    } 

    .hitstylee ul {
      padding: 0;
      margin: 0;
      list-style: none;
      flex-wrap: wrap;
      /* display: flex; */
      flex-wrap: wrap;
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      /* padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5); */
      margin-top: var(--bs-gutter-y);
    
      /* list-style-type: none;
      margin: auto;
      padding: auto;
      display: block; */
    }
    .hitstylee ul li {
      padding: 0;
      margin: 0;
      /* flex: 0 0 50%;
        max-width: 50%; */
      /* max-width: 33.333333%; */
      /* flex: 0 0 33.333333%; */
      flex: 0 0 100%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      list-style: none;
      /* display: block; */
      /* list-style-type: none;
      margin: auto;
      padding: auto;
      display: block; */
    }

  }
@media screen and (max-width: 576px) {
  .mainCategyCard-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mainCategoryCardImg {
    width: 100%;
    height: 203px;
    /* width: 151.006591796875px; */
    border-radius: 0px;
  }
}

.slick-next{
    right: 0 !important;
}
.slick-prev{
    left: 0 !important;
}
.slick-disabled{
    display: none;
    visibility: hidden;
}
.desktop-slide{
    display: contents;
}
.mob-slide{
    display:none
}
.minitablet-slide{
    display: none;
}
.tablet-slide{
    display: none;
}
.notebook-slide{
    display:none;
}
.latest_trend_img{
    aspect-ratio: 471/629;
}
@media screen and (max-width: 1200px) {
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:none
    }
    .minitablet-slide{
        display: none;
    }
    .tablet-slide{
        display: none;
    }
    .notebook-slide{
        display:contents;
    }
}
@media screen and (max-width: 992px) {
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:none
    }
    .minitablet-slide{
        display: none;
    }
    .tablet-slide{
        display: contents;
    }
    .notebook-slide{
        display:none;
    }
}
@media screen and (max-width: 768px) {
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:none
    }
    .minitablet-slide{
        display: contents;
    }
    .tablet-slide{
        display: none;
    }
    .notebook-slide{
        display:none;
    }
}
@media screen and (max-width: 576px) {
    .latest_trend_img{
        aspect-ratio: 77/103;
    }
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:contents
    }
    .minitablet-slide{
        display: none;
    }
    .tablet-slide{
        display: none;
    }
    .notebook-slide{
        display:none;
    }
}

/* @import '../../../public/assets/searchicon.png' */
body {
  overflow-x: hidden;
}

.innerpage-desc {
  padding-left: 2rem;
  padding-right: 8rem !important;
}
.cart-icons {
  padding: 2px;
}
.product-rugs {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.875px;
}
/* algolia work css start */
.hitstyle {
  /* text-align: --web; */
  flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
}
.hitstyle button {
  width: 100%;
  text-align: -webkit-center;
  border: none;
  margin: 5rem 0;

  background: transparent;
}
.hitstyle button:focus {
  outline: none;
}
.hitstyle button:hover {
  outline: none;
  background-color: transparent;
}
.hitstyle ul {
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.hitstyle ul li {
  /* flex: 0 0 50%;
  max-width: 50%; */
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  list-style: none;
  display: inline;
}
/* btn */
.pagination-btn ul li {
  list-style: none;
  display: inline;
}
.pagination-btn a {
  text-decoration: none;
}

.showmore-btn {
  background-color: black;
  height: 47px;
  width: 270px;
  border-radius: 0px;
  z-index: 2;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

/* btn */
/* algolia work css end */
/* .mainscreen {
  /* padding: 3rem; */

/* padding-left: 10rem; */
/* padding-right: 5rem; */
.mainscreen img {
  max-height: 520px;
  max-width: auto;
  border-radius: 0px;
}
.links a {
  text-decoration: none;
  text-align: center;
  font-family: "Roboto", normal;
  color: black;
  /* padding-right: 10px; */
  margin-bottom: 10px;
}
/* checkbox editable  start*/

/* The contain */
.checkbox-label {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;

  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
}
/* .text-white {
  color: white;
} */

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.product-title {
  margin: 3rem 0 0 3rem;
}
.no-image-bg{
  background-color: #ededed;
}
/* checkbox editable end */
/* .productcardimg {
  aspect-ratio: 270/270;
} */
.p16font {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25em;
  text-align: left;
  display: block;
}
.single-product-title {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25em;
  text-align: left;
  display: block;
}
.p14font {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}
.p18font {
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}

.sub-img {
  display: inline-flex;
}
.sub-imgpart {
  max-height: 89px;
  max-width: auto;
  left: 180.333251953125px;
  top: 757px;
  border-radius: 0px;
  border: 1px solid black;
}
.filter-text {
  font-size: 18px;
  color: #828282;
}
.filterbtn option {
  padding-right: 5rem;
  height: 48px;
}
.filterbtn {
  /* styling */
  background-color: white;
  border: 0.1 solid black;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 100%;
  background-color: transparent;
  height: 48px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAB7UlEQVRIie2QT0iTcRjHv7+971uH6lAwooIOttlqcxphFBEM3R/eFljBS4FFBBV0CYROnTrUPTxKXSI6GEFZMX21HKsRJrbed8NteuigRSGEDY3Z9nueTobUnKt53Of6fb6fLzxAgwb1opy9eCXb7Gudydqp/HqK2wPB4PbdTY8Vj9eXU1Tt4R5vy2w+baXXQ37wWDBKwAOSfEmZTFsfm1vbEqpw3Hf7/PP5tDVRj/xQZ/gkEfdBlrs+JEffKACQs1KzHv+BIQf4rsvrL05l7PH/kbcHwt1EfIdJ6qlkfAIAlOUwZ6e+un1tgw5Qn8uzX5uezCT/Sd6hX2XmW0KIyPvEy9+vVlYeTWWsuSbXvqdQ1V73Xu+W6WwmUYv8cOfxa2C6rkJ0vIubuZWZqFQ4YRi7iNWRclkOmk/6e6rL9RsMvkAKBcdNc+bPvOIAAEROn9vBKA1zmUaGBx71AOC/5KHobRCdEqyE3r56/qmSZ9UBAIgYxjb5E0OSyR4daLkM3KTl3pFQtJeJjmobEHkdi82t5qg6AAABw9js+CFfSNAXsTjf7XQ6+fP34j3ikpsKQh8bixWq9dccAIBw+PymJa3wjIm+MTNLyVsXRKnLNs3Ftbo1DQCAS9c37mStn5mgLC2cicfjxVq7DRrUxy9bgs+Fme8W0AAAAABJRU5ErkJggg==);
  background-position: calc(100% - 20px) calc(0.8em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 15px;
  background-repeat: no-repeat;
}
/* display none and visible */
.desktop-visible {
  display: content;
}
.mob-visible {
  display: none;
}
.mob-search {
  display: none;
}
@media screen and (min-width: 1200px) {

  .productcardimg {
    width: 270px;
    height: 270px;
    /* aspect-ratio: 270/270; */
  }

}
@media screen and (max-width: 1200px) {

  .productcardimg {
    /* max-width: 270px; */
    /* height: 270px; */
    aspect-ratio: 270/270;
  }

}

@media screen and (max-width: 992px) {
  .innerpage-desc {
    padding-left: 0;
    padding-right: 6rem !important;
  }
  /* .productcardimg {
    height: 240px;
    width: 240px;
  } */

  /* checkbox editable  start*/

  /* The contain */
  .checkbox-label {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
  }
  /* .text-white {
  color: white;
} */

  /* Hide the browser's default checkbox */
  .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid black;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .contain input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .contain .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* checkbox editable end */
}
.sub-imgpart {
  width: auto;
  height: 89px;
}
.mob-subimgmax {
  /* max-width: 79.017093658447266px; */
  max-width: auto;
  max-height: 79.76179885864258px;
  /* margin:1rem; */
}
.displayscreen {
  text-align-last: center;
}

@media screen and (max-width: 1200px) {
  .main-img {
    width: 300px;
    height: 300px;
  }
  /* .mainscreen {
    padding: 3rem;

    padding-left: 5rem;
  }
  .mainscreen img {
    height: 520px;
    width: 358px; 
  }*/
}
@media screen and (max-width: 992px) {
  .main-img {
    width: 300px;
    height: 300px;
  }
  /* .in {
    padding: 3rem;

    padding-left: 3rem;
    padding-right: 3rem !important;
  }
  .mainscreen img {
    height: 420px;
    width: 308px;
  } */
}

@media screen and (min-width: 575px) and (max-width: 768px) {
  .screen {
    padding: 5rem;
  }
  .innerpage-desc {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important ;
  }
  .hitstyle ul li {
    /* flex: 0 0 50%;
    max-width: 50%; */
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    list-style: none;
    display: inline;
  }
  /* .mainscreen {
    padding: 3rem;

  }
  .mainscreen img {
    height: 420px;
    width: 308px;
  } */
  .main-img {
    width: 300px;
    height: 300px;
  }
  /* .mainscreen {
    padding: 3rem;
    position: relative;
    padding-left: 2rem;
    padding-right: 3rem !important;
  }
  .mainscreen img {
    position: absolute;
    height: 380px;
    width: 288px;
  } */
  .productcardimg {
    max-height: 160px;
    max-width: 160px;
  }
  .p16font {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.25em;
    text-align: left;
  }
  .single-product-title {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.25em;
    text-align: left;
  }
  .p14font {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: contents;
  }

  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .search-padding {
    /* padding-top: 4.5rem; */
  }
  .innerpage-desc {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important ;
  }
  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: contents;
  }
  .displayscreen {
    text-align-last: center;
  }
  .product-rugs {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .mob-subimgmax {
    max-width: 40.017093658447266px;
    max-height: 39.76179885864258px;
  }
  .sub-imgpart {
    height: 55.017093658447266px;
    width: 55.76179885864258px;
    /* left: 89.213623046875px; */
    /* top: 405.15087890625px; */
    border-radius: 0px;
    border: 1px solid black;
  }
  .product-title {
    margin: 3rem 0 0 0;
  }
  .mob-search {
    display: contents;
  }
  .main-img-mob {
    height: 230px;
    width: 158px;
    left: 110px;
    top: 159px;
    border-radius: 0px;
  }
  button.ais-SearchBox-submit {
    background: transparent;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .searchbtn {
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 1px solid 5rem;

    /* background-image: url("../../component/assets/searchicon.png"); */
    /* background-image: url('../../../public/assets/searchicon.png'); */
    background-position: calc(100% - 20px) calc(0.5em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 25px;
    background-repeat: no-repeat;
  }
  .searchbtn input {
    border: none;
    /* border-radius:5rem; */
    background-color: #f5f5f5;
    padding: 16px;
    width: 85%;
  }
  .searchbtn button {
    background-color: #f5f5f5;
    background-color: transparent;
    border: transparent;
    /* background-image:url('../assets/searchicon.png'); */
    /* display: none; */
  }
  .searchbtn input :focus {
    border: none;
    text-decoration: none;
  }
  .mob-filterbtn ul {
    padding: 0;
    margin: 0;
  }
  .filterbtn option {
    padding-right: 5rem;
    height: 40px;
  }
  .filterbtn {
    /* styling */
    background-color: white;
    border: 0.1 solid black;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    width: 100%;
    background-color: transparent;
    height: 40px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAB7UlEQVRIie2QT0iTcRjHv7+971uH6lAwooIOttlqcxphFBEM3R/eFljBS4FFBBV0CYROnTrUPTxKXSI6GEFZMX21HKsRJrbed8NteuigRSGEDY3Z9nueTobUnKt53Of6fb6fLzxAgwb1opy9eCXb7Gudydqp/HqK2wPB4PbdTY8Vj9eXU1Tt4R5vy2w+baXXQ37wWDBKwAOSfEmZTFsfm1vbEqpw3Hf7/PP5tDVRj/xQZ/gkEfdBlrs+JEffKACQs1KzHv+BIQf4rsvrL05l7PH/kbcHwt1EfIdJ6qlkfAIAlOUwZ6e+un1tgw5Qn8uzX5uezCT/Sd6hX2XmW0KIyPvEy9+vVlYeTWWsuSbXvqdQ1V73Xu+W6WwmUYv8cOfxa2C6rkJ0vIubuZWZqFQ4YRi7iNWRclkOmk/6e6rL9RsMvkAKBcdNc+bPvOIAAEROn9vBKA1zmUaGBx71AOC/5KHobRCdEqyE3r56/qmSZ9UBAIgYxjb5E0OSyR4daLkM3KTl3pFQtJeJjmobEHkdi82t5qg6AAABw9js+CFfSNAXsTjf7XQ6+fP34j3ikpsKQh8bixWq9dccAIBw+PymJa3wjIm+MTNLyVsXRKnLNs3Ftbo1DQCAS9c37mStn5mgLC2cicfjxVq7DRrUxy9bgs+Fme8W0AAAAABJRU5ErkJggg==);
    background-position: calc(100% - 20px) calc(0.8em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  .hitstyle ul {
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    /* padding-right: 10%; */
    padding-left: 10%;
    margin-top: var(--bs-gutter-y);
  }

  .hitstyle ul li {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    list-style: none;
    display: inline;
  }
  .productcard {
    max-width: 152px;
    max-height: auto;
    border: 0;
    /* margin: 0 .5rem; */
  }
  .productcardimg {
    max-height: 146.368408203125px;
    max-width: 152px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }
  .checkboxfilter {
    display: none;
  }
  .bestsell {
    display: none;
  }
  .mainscreen {
    /* padding: 3rem; */
    position: relative;
    /* padding-left:1rem;
    padding-right: 3rem !important; */
  }
  .mainscreen img {
    /* position: absolute; */
    max-height: 380px;
    max-width: 288px;
  }
}

/* .loc_icon {
  width: 28px;
  height: 16px;
}

.store_icon {
  width: 28px;
  height: 16px;
} */

.desktop-slide {
  display: contents;
}
.mob-slide {
  display: none;
}
.minitablet-slide {
  display: none;
}
.tablet-slide {
  display: none;
}
.notebook-slide {
  display: none;
}
.RecommedationSlicker {
  /* width: 270px; */
  /* height: 280px; */
  aspect-ratio: 270/285;
}
.recommendationbg {
  background: linear-gradient(180deg, rgba(39, 65, 86, 0) 0%, #274156 100%);
  opacity: 0.5;
}
@media screen and (max-width: 1200px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: none;
  }
  .minitablet-slide {
    display: none;
  }
  .tablet-slide {
    display: none;
  }
  .notebook-slide {
    display: contents;
  }
}
@media screen and (max-width: 992px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: none;
  }
  .minitablet-slide {
    display: none;
  }
  .tablet-slide {
    display: contents;
  }
  .notebook-slide {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: none;
  }
  .minitablet-slide {
    display: contents;
  }
  .tablet-slide {
    display: none;
  }
  .notebook-slide {
    display: none;
  }
  .RecommedationSlicker {
    width: 153px;
    height: 162px;
    /* aspect-ratio: 153/162; */
  }
}
@media screen and (max-width: 576px) {
  .desktop-slide {
    display: none;
  }
  .mob-slide {
    display: contents;
  }
  .minitablet-slide {
    display: none;
  }
  .tablet-slide {
    display: none;
  }
  .notebook-slide {
    display: none;
  }
  .recommendationbg {
    background: linear-gradient(180deg, rgba(39, 65, 86, 0) 0%, #274156 100%);
    opacity: 0.5;
  }
  .RecommedationSlicker {
    width: 153px;
    height: 162px;
  }
}

#single-product-description  {
  color: #65635f;
  padding: 1rem 0;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
#single-product-description  ol{
padding: 0 0 0 1rem;
}
#single-product-description  ul{
padding: 0 0 0 1rem;
}
#single-product-description  li{
  color: #65635f;
  /* padding: 1rem 0; */
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
/* #single-product-description > div > p:nth-child(1) {
  
} */
#single-product-description > div > strong {
  padding: 1rem 0;
  font-size: large;
  line-height: 4rem;
}
#single-product-description > div > p > strong {
  padding: 1rem 0;
  font-size: large;
  line-height: 4rem;
}
#single-product-description > div > p {
  padding: 0;
  margin: 0;
  
}

/* #single-product-description > div > strong > u; */

.input-border {
  border: 1px solid var(--royal-blue);
}
.input-border:focus {
  box-shadow: none;
  outline: none;
}
input {
  border: 1px solid var(--royal-blue);
  height: 3rem;
}
input:focus {
  box-shadow: none;
  outline: none;
}
.name-inpt {
  padding-right: 0;
}
.contact-para{
    font-size: 20px;
    line-height: 30px;
}

.about-us-image {
  aspect-ratio: 2560 / 403;
  width: -webkit-fill-available;
}
li {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

.addressinput:focus{
border:2px solid   #081329;
}
@media screen and (max-width: 575px) {
    .delete-add-desk{
        display: none;
    }

    .address-list-item .btns{
        flex-direction: column;
        align-items: flex-end;
    }
}

@media screen and (min-width: 575px) {
    .delete-add-mob{
        display: none;
    }
    .address-list-item button:first-child{
        margin-right: 10px;
    }
    
}


.delete-add-mob img{
    height: 15px;
    margin-bottom: 40px;
}

.address-list-item button{
    padding: 10px 25px;
    font-size: 0.8rem;
}


.profile-menu-item img{
    height: 20px;
}

.profile-menu-item p{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 18px; */
    letter-spacing: 0px;
    text-align: left;
    font-family: CircularStd;

}

.profile-menu-item{
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid  var(--grey);
}

.profile-edit .accordion__button{
    background-color: white;
    border: none;
}

.profile-edit .accordion__panel{
    padding: 15px;
}

.profile-edit-contianer{
    margin-top: 5%;
}

.profile-edit .accordion__button{
    padding: 10px 15px 10px 15px;
    font-size: 0.85rem;
    font-weight: 900;
}

.profile-edit .accordion__item + .accordion__item{
    border-top: none;
    border-bottom: 1px solid  var(--grey);
}

.accordion__button:focus{
    outline: none;
}

.small-normal{
    /* font-weight: 900; */
    font-size: 0.9rem;
    font-family: CircularStd;
    font-weight: 400;
}

.small-bold{
    font-weight: 900;
    font-size: 1rem;
    font-family: CircularStd;
}


.profile-input{
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px 0;
    border-bottom: 1px solid  var(--grey);
    margin-bottom: 20px;
}

.profile-input::-webkit-input-placeholder { /* Edge */
    font-size: 0.8rem;
    color: var(--blackmain);
    font-weight: 900;
    font-family: CircularStd;
  }
  
  .profile-input::placeholder {
    font-weight: 900;
    font-size: 0.8rem;
    color: var(--blackmain);
    font-family: CircularStd;
  }

  #personal_data button{
      width: 100%;
      font-size: 0.8rem;
  }


  .profile-header{
    display: flex;
    align-items: center;
    margin-top: 5%;

  }

  .profile-header img{
    height: 20px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }


  /* ADDRESS LIST */
.address-list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid  var(--grey);

}

.shipping-list-container{
    margin-top: 5%;
    margin-bottom: 15%;
}

.address-list-item p{
    width: 40%;
}

.address-list-item .btns{
    display: flex;
}

.absolute-btn{
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding-bottom: 10px;
}

@media screen and (max-width: 575px) {
    .delete-add-desk{
        display: none;
    }

    .address-list-item .btns{
        flex-direction: column;
        align-items: flex-end;
    }
    .list-mob{
        display: none;
    }
    .order-thumb{
        margin-bottom: 5%;
    }
    .small-bold:hover{
        color: #404040;
    }
}
.clear-all{
    border: 1.8px solid #404040;
}
.accordion__item{
    border-bottom: 1px solid #e8e8e8;
}
@media screen and (min-width: 575px) {
    .delete-add-mob{
        display: none;
    }
    .address-list-item button:first-child{
        margin-right: 10px;
    }
    .shipping-address{
        padding: 2%;
    }
    .singleorder-ships .ship-details{
    font-family: 'CircularStd', serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    }
    .singleorder-ships .order-list-price{
        font-family: 'CircularStd', serif;
    }
    .singleorder-ships-table{
        padding-right: 0;
        display: flex;
        align-content: space-between;
    }
    .singleorder-ships{
        height: 100%;
        padding: 10% 6% 6% 6%;
        display: flex;
        align-content: space-between;
    }
    .singleorder-ships .order-list-price{
        padding-bottom: 10%;
    }
    
}

.order-thumb .cart-item p.brown{
    display: none;
}

.order-thumb .shipping-list-container{
    margin-bottom: 2%;
}

.order-accordions .order-list-item{
    flex-direction: column;
}

.order-accordions .order-list-image{
    margin-right: 0;
}

.order-accordions .order-list-view{
    display: none;
}

.order-accordions .order-list-qty{
    display: none;
}

.order-accordions .order-thumb .order-list-image > img{
    width: 100%;
}

.order-accordions .accordion__panel{
    padding: 0;
}

.order-accordions .accordion__button{
    background-color: #fff;
}

.order-accordions .accordion{
    border: none;
}

.order-number{
    justify-content: space-between;
}

.d-menu-item{
    cursor: pointer;
}

.delete-add-mob img{
    height: 15px;
    margin-bottom: 40px;
}

.address-list-item button{
    padding: 10px 25px;
    font-size: 0.8rem;
}


.profile-menu-item img{
    height: 20px;
}

.profile-menu-item p{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 18px; */
    letter-spacing: 0px;
    text-align: left;
    /* font-family: CircularStd; */

}

.profile-menu-item{
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid  var(--royal-blue);
}

.profile-edit .accordion__button{
    background-color: white;
    border: none;
}

.profile-edit .accordion__panel{
    padding: 15px;
}

.profile-edit-contianer{
    margin-top: 5%;
}

.profile-edit .accordion__button{
    padding: 20px 15px 20px 15px;
    font-size: 0.85rem;
    font-weight: 900;
}

.profile-edit .accordion__item + .accordion__item{
    border-top: none;
    border-bottom: 1px solid  var(--royal-blue);
}

.accordion__button:focus{
    outline: none;
}

.small-normal{
    /* font-weight: 900; */
    font-size: 0.9rem;
    /* font-family: CircularStd; */
    font-weight: 400;
}

.small-bold{
    font-weight: 900;
    font-size: 1rem;
    /* font-family: CircularStd; */
}


.profile-input{
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px;
    border-bottom: 1px solid  var(--royal-blue);
    margin-bottom: 20px;
}

.profile-input::-webkit-input-placeholder { /* Edge */
    font-size: 0.8rem;
    color: var(--royal-blue);
    font-weight: 900;
  }
  
  .profile-input::placeholder {
    font-weight: 900;
    font-size: 0.8rem;
    color: var(--royal-blue);
  }

  #personal_data button{
      width: 100%;
      font-size: 0.8rem;
  }


  .profile-header{
    display: flex;
    align-items: center;
    margin-top: 5%;

  }

  .profile-header img{
    height: 20px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }


  /* ADDRESS LIST */
.address-list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid  var(--royal-blue);

}

.shipping-list-container{
    margin-top: 5%;
    margin-bottom: 15%;
}
.open-orders .shipping-list-container{
    margin-bottom: 0;
}
.address-list-item p{
    width: 40%;
}
.my-orders-list .list-mob{
    display: none;
}
.address-list-item .btns{
    display: flex;
}

.absolute-btn{
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding-bottom: 10px;
}


/* profiledetails */
@media screen and (max-width: 799px) {
    
    .mobile-div{
        margin-bottom: 40px;
    }
    
}
@media screen and (min-width: 800px) {
    .mobile-div{
        display: none;
    }
    .desktop-div{
        display: flex;
        margin-top: 70px;
        margin-bottom: 70px;
    }
}


.desktop-div > div:first-child{
    width: 30%;
    margin-right: 51px;
}
.desktop-div > div:nth-child(2){
    width: 60%;
}

.Desktop-buttons{
    border-bottom: solid 1px #F1F2F3;
    display: flex;
}
.Desktop-buttons > a > button :focus{
    border-bottom: solid #1D1F22;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: 0 !important;
}


.Desktop-buttons > a > button{
    border-top: none;
    border-left: none;
    border-right: none;
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #72757E;;
    background: #ffffff;
    margin-right: 15px;
    padding: 5px 0px;
    outline: 0 !important;


}
/* .Desktop-buttons > a > button {
color: #72757E;
color: #1D1F22;
} */


/* //desktopmenu */
.d-menu{
    background: #F1F2F3;
    padding: 40px;

}
.d-menu-heading{
font-family: "DM Sans", sans-serif;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 120%;
color: #1D1F22;
}
.d-menu-item2{
    
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 130%;
color: #1D1F22;
margin: 16px 0px 0px;
}
.d-menu-item1{
    
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 130%;
color: #1D1F22;
margin: 25px 0px 0px;
}
.d-menu-item{

font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 130%;
color: #404040;
margin: 20px 0px 0px;
}

/* personalData */
@media screen and (min-width: 800px) {
    #personal_data{
     margin-top: 40px;
    }
    .small-normal{
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: #404040;
    }
    .fields-sec > input{
        
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #1D1F22;
        margin-top: 20px;
        /* height: 69px; */
    }
    .bullets.row{
        margin: 8% 0;
    }
    .row{
        margin: 0px;
    }
    #personal_data .change-details-btn{
        width: 240px;
        height: 56px;
        margin-top: 40px;
    }
    .policy-text{
        
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 130%;
        color: #000000;
        padding: 0px;
    }
    
    
}

/* shippingList */
@media screen and (min-width: 800px) {
    #ship-change-details-btn,.absolute-btn .ship-change-details-btn{
        width: 240px;
        height: 56px;
        margin-top: 40px;
    }
    .btn-shiplist{
        width:240px;
    }
    .address-list-item-para{
        
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #1D1F22;

    }
    .address-list-item{
        padding: 30px 0px;
    }
    /* .btns > button{
        width: 128px;
        height: 56px;
    } */
    #profile-edit > .pad-0{
        padding: 0px;
    }
}
@media screen and (max-width: 799px) {
    .address-list-item-para{
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #1D1F22;
    }
    .d-menu-item2{
    
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        color: #1D1F22;
        margin: 9px 0px 0px;
        }
}

/* address change */
@media screen and (min-width: 800px) {
    #ship-change-details-btn{
        width: 240px !important;
        height: 56px;
        margin-top: 40px;
    }
}

/* my orders */
.order-list{
    padding-bottom: 25px;
}
.order-list-item{
    background: #F1F2F3;
}
.order-list-item{
    padding: 40px;
    display: flex;
}

.order-list-image{
    margin-right: 30px;
}

.order-list-title{
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    flex-direction: column;
}
.order-list-title > div{
    font-family: Circular Std;
}
.order-list-dimensions, .order-list-matstyle, .order-list-framesize, .order-list-qty{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.order-list-price{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    font-family: Circular Std;
}

.order-list-image-details-span{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: #8C8C8C;
}

.order-list-image-details > div{
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
}
@media screen and (max-width: 799px) {
    .order-list-image > img{
        width: 124px;
        height: auto;
    }
    .order-list-item{
        padding: 15px;
        /* display: flex; */
    }
    .order-list-image-details-di{
        display: none;
    }
    .order-list-title{
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 600;
    }
    .order-list-price{
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        font-family: unset;
        align-items: center;
        display: flex;   
    }
    .order-list-qty{
        font-size: 12px;
        font-style: normal;
        font-weight: normal;

    }
    .order-list-view{
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        font-style: normal;
        font-weight: normal;
        color: #6C3F2C;
    }
   
}
@media screen and (min-width: 800px) {
    .order-list-image > img{
        width: 212px;
        height: auto;
    }
    .order-list-image-details-mob{
        display: none;
    }
}



.selected-address {
  background-color: rgba(233, 251, 245, 255);
  border: 1px solid rgb(66 163 131);
}


.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}
.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  -webkit-transform: scale(0.84);
          transform: scale(0.84);
  border-radius: 50%;
  -webkit-animation: animateContainer 0.75s ease-out forwards 0.75s;
          animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.check-container .check-background svg {
  width: 65%;
  -webkit-transform: translateY(0.25rem);
          transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  -webkit-animation: animateCheck 0.35s forwards 1.25s ease-out;
          animation: animateCheck 0.35s forwards 1.25s ease-out;
}
.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #49da83, transparent);
  -webkit-animation: animateShadow 0.75s ease-out forwards 0.75s;
          animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@-webkit-keyframes animateContainer {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}
@-webkit-keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}

 
.circle,
.circle-border {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: success-anim 700ms ease;
          animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-animation: circle-anim 400ms ease;
          animation: circle-anim 400ms ease;
  background: #f86;	
}

@-webkit-keyframes success-anim {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  30% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes success-anim {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  30% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes circle-anim {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@keyframes circle-anim {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

.error::before,
.error::after {
  content: "";
  display: block;
  height: 4px;
  background: #f86;
  position: absolute;
}

.error::before {
  /* width: 40px; */
  width: 5rem;
  /* height: 7.5rem; */
  top: 48%;
  left: 16%;
  -webkit-transform: rotateZ(50deg);
          transform: rotateZ(50deg);
}

.error::after {
  /* width: 40px; */
  width: 5rem;

  top: 48%;
  left: 16%;
  -webkit-transform: rotateZ(-50deg);
          transform: rotateZ(-50deg);
}
.about-us-image {
  aspect-ratio: 2560 / 403;
  width: -webkit-fill-available;
}
li {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

.about-us-image {
  aspect-ratio: 2560 / 403;
  width: -webkit-fill-available;
}
li {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

.about-us-image {
  aspect-ratio: 2560 / 403;
  width: -webkit-fill-available;
}
li {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

/* variable */
:root {
  --primary-gold: #c79b4f;
  --primary-grey: #8e8e8e;
  --secondary-grey: #dcddde;
  --primary-grey-light: #efefef;
  --secondary-grey-light: #fbfbfb;
  --greyish: #f4f4f2;
  --royal-blue: #081329;
  --dark-blue: #274156;
}
.overlay-bg {
  background: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, #274156 100%);
  opacity: 0.8;
}
.ais-SearchBox-reset{
  display: none;
}
body {
  background-color: white;
  color: black;
  overflow-x: hidden;
  font-family: "DM Sans", sans-serif;
}
/* button:hover {
  outline: none;
  background: none;
} */
.hover-pointer:hover {
  background-color: #f2f2f2;
}
.btn:hover {
  color: white;
  outline: none;
  border-radius: 0;
}
.btn:active {
  background-color: #081329;
  background-color: var(--royal-blue);
}
.btn:active {
  background-color: #081329;
  background-color: var(--royal-blue);
}
/* button:hover {
  background-color: var(--royal-blue);
  z-index: 11;
}
button:hover {
  background-color: var(--royal-blue);
  z-index: 11;
} */
.reset-bg {
  background-color: #e9ecef;
}
.reset-form {
  width: 500px;
  border: 1px;
}
.resetlogo {
  width: 100px;
  height: 40px;
}
.resetbtn {
  height: 40px;
}
.resetbtn:hover {
  color: white;
}
.whitebtn:hover {
  background-color: #081329;
  background-color: var(--royal-blue);
  z-index: 111;
}
.whitebtn:active {
  background-color: #081329;
  background-color: var(--royal-blue);
  z-index: 111;
}
/* .btn:focus { background-color:var(--royal-blue) } */
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}
.btn-border {
  border-radius: 0;
  border: 0;
}
/* .hitstyle ul li {
  list-style: none;
} */

.card {
  border: 0;
  border-radius: 0;
}
.card-img {
  border: 0;
  border-radius: 0;
}
.card-img-top {
  border: 0;
  border-radius: 0;
}
.mouse-pointer {
  cursor: pointer;
}
.text-black {
  color: black;
}
.color-blue {
  color: #274156;
}
.contain {
  margin: 2rem;
}
.list-style-lower-alpha{
  list-style: lower-alpha;
}
.list-style-upper-alpha{
  list-style:upper-alpha
}
.list-style-number{
  list-style:decimal
}

.list-style-none {
  list-style-type: none;
}
/* background */
.bg-shadow {
  background-color: rgba(0, 0, 0, 0.1);
}
.bg-black {
  background-color: black;
  z-index: 4;
}
.bg-buynow {
  background-color: #fb641b;
}
.bg-buynow:hover {
  background-color: #fb641b !important;
}
.bg-royalblue {
  background-color: #081329 !important;
  background-color: var(--royal-blue) !important;
  background: #081329 !important;
  background: var(--royal-blue) !important;
}
.bg-royalblue:hover {
  background-color: #081329;
  background-color: var(--royal-blue);
  background: #081329;
  background: var(--royal-blue);
}
.bg-grey {
  background-color: #efefef;
  background-color: var(--primary-grey-light);
  background: #efefef;
  background: var(--primary-grey-light);
}
.bg-greyish {
  background-color: #f4f4f2;
  background-color: var(--greyish);
  background: #f4f4f2;
  background: var(--greyish);
}
.pl-8rem {
  padding-left: 8rem;
}
.px-8rem {
  padding-left: 8rem;
  padding-right: 8rem;
}
/* margin */
.mr-8rem {
  /* margin-right:8rem; */
  padding-right: 8rem;
}
/* width */
.w-full {
  width: 100%;
  z-index: 100;
}
.w-11rem {
  width: 11rem;
}
.w-17rem {
  width: 17rem;
}
.w-19rem {
  width: 19rem;
}
.w-21rem {
  max-width: 21rem;
}
.w-25rem {
  width: 25rem;
}
.w-27rem {
  width: 27rem;
}
.my-5rem {
  margin: 0 5rem;
}
.py-5rem {
  padding: 0 5rem;
}
.pb-10 {
  padding-bottom: 10rem;
}
.lh-5 {
  line-height: 5px;
}
.letterspace-25 {
  letter-spacing: 0.25em;
}
.h1 {
  font-family: "DM Sans", sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0em;
}
.h1-light {
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.02em;
  /* text-align: left; */
  color: #081329;
  color: var(--royal-blue);

  /* color:var */
}
h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}
.h3-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}
.ag-16px {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
}
.h4-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
.p16 {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  display: block;
}
.p16-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}
.p14 {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0px;
  text-align: left;
}
.p14-500 {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.p14-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.p12 {
  font-family: " DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}
.p12-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.l20 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  display: block;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
.l14-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.l12 {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.l12-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.l34 {
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}
.displayscreen {
  text-align-last: center;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.p-3px {
  padding: 3px !important;
}
.desk-mpl5 {
  padding: 0 0 0 5rem;
  /* margin: 0 0 0 3rem; */
}
.px-1rem {
  padding-left: 0.1rem !important;
  margin-left: 0.1rem !important;
}
.banner-title {
  margin-top: 25%;
  max-width: 450px;
  color: #081329;
  color: var(--royal-blue);
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
}
.bann-title {
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
}
/* top: 448px; */
/* text-color */
.text-grey {
  color: #65635f;
}
.text-black {
  color: black;
}
.royal-blue-text {
  color: #081329;
  color: var(--royal-blue);
}
.valid-error {
  font-size: 10px;
  color: red;
}

/* slicker part start */
.slickerpart {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
/* slicker part end */
/* button {
  background-color: var(--dark-blue);
  border: 1 px solid var(--dark-blue);
  height: 56px;
  width: 189px;
  left: 22px;
  top: 602px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 700;
  border-radius: 0px;
} */
.small-icon {
  width: 16px;
  height: 16px;
}
.linked a {
  text-decoration: none;
  list-style-type: none;
}
/* .slicker p {
  position: absolute;
  bottom: 0;
  padding-left: 2.5rem;
} */
.title p {
  width: 2rem;
  text-align: center;
  border-bottom: 1px solid #081329;
  border-bottom: 1px solid var(--royal-blue);
  line-height: 0.3em;
}
.title .title-margin1 {
  margin: 10px 0 29px;
}
.title .title-margin2 {
  margin: 10px 0 26px;
}
.title1 p {
  width: 4.5rem;
  text-align: center;
  border-bottom: 2px solid #081329;
  border-bottom: 2px solid var(--royal-blue);
  line-height: 0.3em;
  /* margin: 10px 0 px; */
  /* margin-bottom: 2%;  */
}
.title1 span {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.overlay-mob {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
}

.desktop-visible {
  display: contents;
}
.largescreen-visible {
  display: none;
}
.notebook-visible {
  display: none;
}
.notebooktwo-visible {
  display: none;
}
.tablet-visible {
  display: none;
}
.mob-visible {
  display: none;
}
.cart-img {
  width: 144px;
  height: 144px;
}
/* .carts-icon {
  width: "30px";
  height: "30px";
  background-color: var(--royal-blue);
} */
/* .cart-box{
  width: sc;
} */

@media screen and (min-width: 992px) {
 

  .tablet-visible {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .banner-title {
    margin-top: 25%;
    max-width: 450px;
    color: #081329;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }

  .tablet-visible {
    display: contents;
  }

  .royalblue-border-btn {
    border: 2px solid #274156;
    border: 2px solid var(--dark-blue);
    height: 36px;
    min-width: auto;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0px;
  }
}

@media screen and (max-width: 768px) {
  .l20 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    display: block;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }
  .desk-mpl5 {
    padding: 0 0 0 3rem;
    /* margin: 0 0.3rem;1 */
  }
  .title .title-margin1 {
    margin: 10px 0 22px;
  }
  .title .title-margin2 {
    margin: 10px 0 21px;
  }
  .tablet-visible {
    display: contents !important;
  }

  .mob-visible {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .reset-bg {
    background-color: #fff;
  }
  .reset-form {
    width: 400px;
    border: 0;
  }
  .desk-mpl5 {
    padding: 0 0 0 1rem;
    /* margin: 0 0.3rem;1 */
  }
  .l20 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    display: block;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.02em;
  }
  .p-3px {
    padding: 0 1rem !important;
  }
  .mob-nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .mob-containter {
    margin: 0 1rem;
  }
  .mob-cont-2 {
    margin: 0 2rem;
  }
  .royalblue-btn {
    border: 2px solid #274156;
    border: 2px solid var(--dark-blue);
    height: 36px;
    min-width: auto;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0px;
  }
  .banner-title {
    margin-top: 0;
    max-width: 284px;
    color: #081329;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .notebooktwo-visible {
    display: none;
  }
  .notebook-visible {
    display: none;
  }
  .tablet-visible {
    display: none;
  }

  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: contents;
  }
  .title p {
    width: 2rem;
    text-align: center;
    border-bottom: 1px solid #081329;
    border-bottom: 1px solid var(--royal-blue);
    line-height: 0.3em;
  }
  .title .title-margin1 {
    margin: 10px 0 22px;
  }
  .title .title-margin2 {
    margin: 10px 0 21px;
  }
  .title p {
    width: 2rem;
    text-align: center;
    border-bottom: 1px solid #081329;
    border-bottom: 1px solid var(--royal-blue);
    line-height: 0.3em;
  }
  .slickerpart {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    /* position: absolute; */
    bottom: 0;
    right: 0;
  }
  /* .slicker p {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: 1.5rem;
  } */

  .overlay-mob {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    bottom: 0;
    /* top: 0; */
    left: 0;
    overflow-y: hidden;
    z-index: 5;
    /* background-color:#fff!important; */
  }

  .cart-img {
    width: 100px;
    height: 100px;
  }
  .carts-icon {
    width: 20px;
    height: 20px;
  }
}

