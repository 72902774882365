.slick-next{
    right: 0 !important;
}
.slick-prev{
    left: 0 !important;
}
.slick-disabled{
    display: none;
    visibility: hidden;
}
.desktop-slide{
    display: contents;
}
.mob-slide{
    display:none
}
.minitablet-slide{
    display: none;
}
.tablet-slide{
    display: none;
}
.notebook-slide{
    display:none;
}
.latest_trend_img{
    aspect-ratio: 471/629;
}
@media screen and (max-width: 1200px) {
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:none
    }
    .minitablet-slide{
        display: none;
    }
    .tablet-slide{
        display: none;
    }
    .notebook-slide{
        display:contents;
    }
}
@media screen and (max-width: 992px) {
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:none
    }
    .minitablet-slide{
        display: none;
    }
    .tablet-slide{
        display: contents;
    }
    .notebook-slide{
        display:none;
    }
}
@media screen and (max-width: 768px) {
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:none
    }
    .minitablet-slide{
        display: contents;
    }
    .tablet-slide{
        display: none;
    }
    .notebook-slide{
        display:none;
    }
}
@media screen and (max-width: 576px) {
    .latest_trend_img{
        aspect-ratio: 77/103;
    }
    .desktop-slide{
        display: none;
    }
    .mob-slide{
        display:contents
    }
    .minitablet-slide{
        display: none;
    }
    .tablet-slide{
        display: none;
    }
    .notebook-slide{
        display:none;
    }
}
