/* variable */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
:root {
  --primary-gold: #c79b4f;
  --primary-grey: #8e8e8e;
  --secondary-grey: #dcddde;
  --primary-grey-light: #efefef;
  --secondary-grey-light: #fbfbfb;
  --greyish: #f4f4f2;
  --royal-blue: #081329;
  --dark-blue: #274156;
}
.overlay-bg {
  background: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, #274156 100%);
  opacity: 0.8;
}
.ais-SearchBox-reset{
  display: none;
}
body {
  background-color: white;
  color: black;
  overflow-x: hidden;
  font-family: "DM Sans", sans-serif;
}
/* button:hover {
  outline: none;
  background: none;
} */
.hover-pointer:hover {
  background-color: #f2f2f2;
}
.btn:hover {
  color: white;
  outline: none;
  border-radius: 0;
}
.btn:active {
  background-color: var(--royal-blue);
}
.btn:active {
  background-color: var(--royal-blue);
}
/* button:hover {
  background-color: var(--royal-blue);
  z-index: 11;
}
button:hover {
  background-color: var(--royal-blue);
  z-index: 11;
} */
.reset-bg {
  background-color: #e9ecef;
}
.reset-form {
  width: 500px;
  border: 1px;
}
.resetlogo {
  width: 100px;
  height: 40px;
}
.resetbtn {
  height: 40px;
}
.resetbtn:hover {
  color: white;
}
.whitebtn:hover {
  background-color: var(--royal-blue);
  z-index: 111;
}
.whitebtn:active {
  background-color: var(--royal-blue);
  z-index: 111;
}
/* .btn:focus { background-color:var(--royal-blue) } */
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}
.btn-border {
  border-radius: 0;
  border: 0;
}
/* .hitstyle ul li {
  list-style: none;
} */

.card {
  border: 0;
  border-radius: 0;
}
.card-img {
  border: 0;
  border-radius: 0;
}
.card-img-top {
  border: 0;
  border-radius: 0;
}
.mouse-pointer {
  cursor: pointer;
}
.text-black {
  color: black;
}
.color-blue {
  color: #274156;
}
.contain {
  margin: 2rem;
}
.list-style-lower-alpha{
  list-style: lower-alpha;
}
.list-style-upper-alpha{
  list-style:upper-alpha
}
.list-style-number{
  list-style:decimal
}

.list-style-none {
  list-style-type: none;
}
/* background */
.bg-shadow {
  background-color: rgba(0, 0, 0, 0.1);
}
.bg-black {
  background-color: black;
  z-index: 4;
}
.bg-buynow {
  background-color: #fb641b;
}
.bg-buynow:hover {
  background-color: #fb641b !important;
}
.bg-royalblue {
  background-color: var(--royal-blue) !important;
  background: var(--royal-blue) !important;
}
.bg-royalblue:hover {
  background-color: var(--royal-blue);
  background: var(--royal-blue);
}
.bg-grey {
  background-color: var(--primary-grey-light);
  background: var(--primary-grey-light);
}
.bg-greyish {
  background-color: var(--greyish);
  background: var(--greyish);
}
.pl-8rem {
  padding-left: 8rem;
}
.px-8rem {
  padding-left: 8rem;
  padding-right: 8rem;
}
/* margin */
.mr-8rem {
  /* margin-right:8rem; */
  padding-right: 8rem;
}
/* width */
.w-full {
  width: 100%;
  z-index: 100;
}
.w-11rem {
  width: 11rem;
}
.w-17rem {
  width: 17rem;
}
.w-19rem {
  width: 19rem;
}
.w-21rem {
  max-width: 21rem;
}
.w-25rem {
  width: 25rem;
}
.w-27rem {
  width: 27rem;
}
.my-5rem {
  margin: 0 5rem;
}
.py-5rem {
  padding: 0 5rem;
}
.pb-10 {
  padding-bottom: 10rem;
}
.lh-5 {
  line-height: 5px;
}
.letterspace-25 {
  letter-spacing: 0.25em;
}
.h1 {
  font-family: "DM Sans", sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0em;
}
.h1-light {
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.02em;
  /* text-align: left; */
  color: var(--royal-blue);

  /* color:var */
}
h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}
.h3-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}
.ag-16px {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
}
.h4-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
.p16 {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  display: block;
}
.p16-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}
.p14 {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0px;
  text-align: left;
}
.p14-500 {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.p14-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.p12 {
  font-family: " DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}
.p12-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.l20 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  display: block;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
.l14-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.l12 {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.l12-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.l34 {
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}
.displayscreen {
  text-align-last: center;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.p-3px {
  padding: 3px !important;
}
.desk-mpl5 {
  padding: 0 0 0 5rem;
  /* margin: 0 0 0 3rem; */
}
.px-1rem {
  padding-left: 0.1rem !important;
  margin-left: 0.1rem !important;
}
.banner-title {
  margin-top: 25%;
  max-width: 450px;
  color: var(--royal-blue);
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
}
.bann-title {
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0em;
}
/* top: 448px; */
/* text-color */
.text-grey {
  color: #65635f;
}
.text-black {
  color: black;
}
.royal-blue-text {
  color: var(--royal-blue);
}
.valid-error {
  font-size: 10px;
  color: red;
}

/* slicker part start */
.slickerpart {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
/* slicker part end */
/* button {
  background-color: var(--dark-blue);
  border: 1 px solid var(--dark-blue);
  height: 56px;
  width: 189px;
  left: 22px;
  top: 602px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 700;
  border-radius: 0px;
} */
.small-icon {
  width: 16px;
  height: 16px;
}
.linked a {
  text-decoration: none;
  list-style-type: none;
}
/* .slicker p {
  position: absolute;
  bottom: 0;
  padding-left: 2.5rem;
} */
.title p {
  width: 2rem;
  text-align: center;
  border-bottom: 1px solid var(--royal-blue);
  line-height: 0.3em;
}
.title .title-margin1 {
  margin: 10px 0 29px;
}
.title .title-margin2 {
  margin: 10px 0 26px;
}
.title1 p {
  width: 4.5rem;
  text-align: center;
  border-bottom: 2px solid var(--royal-blue);
  line-height: 0.3em;
  /* margin: 10px 0 px; */
  /* margin-bottom: 2%;  */
}
.title1 span {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.overlay-mob {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
}

.desktop-visible {
  display: contents;
}
.largescreen-visible {
  display: none;
}
.notebook-visible {
  display: none;
}
.notebooktwo-visible {
  display: none;
}
.tablet-visible {
  display: none;
}
.mob-visible {
  display: none;
}
.cart-img {
  width: 144px;
  height: 144px;
}
/* .carts-icon {
  width: "30px";
  height: "30px";
  background-color: var(--royal-blue);
} */
/* .cart-box{
  width: sc;
} */

@media screen and (min-width: 992px) {
 

  .tablet-visible {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .banner-title {
    margin-top: 25%;
    max-width: 450px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }

  .tablet-visible {
    display: contents;
  }

  .royalblue-border-btn {
    border: 2px solid var(--dark-blue);
    height: 36px;
    min-width: auto;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0px;
  }
}

@media screen and (max-width: 768px) {
  .l20 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    display: block;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }
  .desk-mpl5 {
    padding: 0 0 0 3rem;
    /* margin: 0 0.3rem;1 */
  }
  .title .title-margin1 {
    margin: 10px 0 22px;
  }
  .title .title-margin2 {
    margin: 10px 0 21px;
  }
  .tablet-visible {
    display: contents !important;
  }

  .mob-visible {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .reset-bg {
    background-color: #fff;
  }
  .reset-form {
    width: 400px;
    border: 0;
  }
  .desk-mpl5 {
    padding: 0 0 0 1rem;
    /* margin: 0 0.3rem;1 */
  }
  .l20 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    display: block;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.02em;
  }
  .p-3px {
    padding: 0 1rem !important;
  }
  .mob-nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }
  .mob-containter {
    margin: 0 1rem;
  }
  .mob-cont-2 {
    margin: 0 2rem;
  }
  .royalblue-btn {
    border: 2px solid var(--dark-blue);
    height: 36px;
    min-width: auto;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0px;
  }
  .banner-title {
    margin-top: 0;
    max-width: 284px;
    color: var(--royal-blue);
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .notebooktwo-visible {
    display: none;
  }
  .notebook-visible {
    display: none;
  }
  .tablet-visible {
    display: none;
  }

  .desktop-visible {
    display: none;
  }
  .mob-visible {
    display: contents;
  }
  .title p {
    width: 2rem;
    text-align: center;
    border-bottom: 1px solid var(--royal-blue);
    line-height: 0.3em;
  }
  .title .title-margin1 {
    margin: 10px 0 22px;
  }
  .title .title-margin2 {
    margin: 10px 0 21px;
  }
  .title p {
    width: 2rem;
    text-align: center;
    border-bottom: 1px solid var(--royal-blue);
    line-height: 0.3em;
  }
  .slickerpart {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    /* position: absolute; */
    bottom: 0;
    right: 0;
  }
  /* .slicker p {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: 1.5rem;
  } */

  .overlay-mob {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    bottom: 0;
    /* top: 0; */
    left: 0;
    overflow-y: hidden;
    z-index: 5;
    /* background-color:#fff!important; */
  }

  .cart-img {
    width: 100px;
    height: 100px;
  }
  .carts-icon {
    width: 20px;
    height: 20px;
  }
}
