.footer-sub-link-parent div {
  border-right: 1px solid white;
  padding:0 15px
}

.footer-sub-link-parent div:last-child {
  border-right: 0;
}
.footer {
  background-color: var(--royal-blue);
  overflow: hidden;
  color: white;
}
.footer  a{
  text-decoration: none !important;
  font-family: Roboto;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.footer span{
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  display: block;
  /* text-align: left; */
  
}

.footer a:hover{
    color:#fff;
    text-decoration: none !important;
    border-bottom:1px solid #fff
}

.footer a :focus{
    color:#fff;
    text-decoration: none !important;
    border-bottom:1px solid #fff

}

.down-footer {
  height: 330px;
  display: flex;
  flex-direction: row;
}

.tags {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: auto;
  width: 30%;
  padding-right: 0.9rem !important;
}
.first-down {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3.3rem;
  padding-bottom: 3rem;
}
.singletag {
  margin-right: 5rem !important;
  white-space: nowrap;
}
.tags a {
  font-family: "Roboto", normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none !important;
  color: #fff;
  border-right: none;
}
.subtag {
  display: flex;
  flex-direction: column;
}
.subtag a {
  font-family: "Roboto", normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
}
.endfooter {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
}
.copy {
  font-family: Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: center;
  padding-left: 6%;
}
.follow {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 0;
  flex-direction: row;
  font-family: Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: right;
  padding-right: 4.9rem !important;
}
.follow span {
  margin-right: 10px;
}
.social {
  height: 29px;
  /* margin-right: 10px; */
}
.yt {
  height: 29px;
  margin-right: 10px;
}
.social img :first-child {
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .footer span{
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    display: block;
    /* text-align: left; */
    
  }
}
@media screen and (max-width: 768px) {
  .social {
    height: 16px;
    margin-right: 3px;
  }
}
@media screen and (min-width: 575px) {
  .down-mob-footer {
    display: none !important;
  }
}
@media screen and (max-width: 575px) {
  .social {
    height: 23px;
    margin-right: 3px;
  }
  .downfooter {
    display: none;
  }
  .sprt-footer {
    display: flex;
  }
  .down-mob-footer .footerlogo {
    text-align: center;
    width: 100%;
    padding-left: 18%;
    margin-left: auto;
    margin-right: auto;
  }
  .sprt-btn {
    display: flex;
    justify-content: center;
  }
  .support {
    font-size: 28px !important;
    display: flex;
    justify-content: center;
  }

  .contain {
    /* padding: 0 !important;c */
  }
}
